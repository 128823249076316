import axios from 'axios';

const markNotificationsRead = async () => {
  const mainNotificationsContainer = document.querySelector('#main-activity-notifications-container');
	const userNotificationsContainer = document.querySelector('#user-activity-notifications-container');
	const listNotificationsContainer = document.querySelector('#list-activity-notifications-container');
  const restaurantNotificationsContainer = document.querySelector('#restaurant-activity-notifications-container');

  if (mainNotificationsContainer) {
    // Get user info
    const { id: userId, baseUrl, count } = document.querySelector('.js-variables-container').dataset;
    const notificationsCount = parseInt(count);
    const url = `${baseUrl}/api/v1/notifications/main/${userId}/read`;

    if (!notificationsCount || notificationsCount === 0) {
      // Return if no active notifications to save some code execution and API request
      return;
    }

    // Send a request to API to mark user notifications as inactive
    const response = await axios.get(url);
    const { notificationsRemaining } = response.data;

    if (!notificationsRemaining) {
      // Make the red dot disappear if no notifications remaining
      const bottomBarHeartNotification = document.querySelector('#notification-alert-heart-bottom-bar');
      bottomBarHeartNotification.classList.add('hidden');
    }
  }

	if (userNotificationsContainer) {
		// Get user info
		const { id: userId, baseUrl, count } = document.querySelector('.js-variables-container').dataset;
		const notificationsCount = parseInt(count);
		const url = `${baseUrl}/api/v1/notifications/users/${userId}/read`;

		if (!notificationsCount || notificationsCount === 0) {
			// Return if no active notifications to save some code execution and API request
			return;
		}

    const userTabHeartNotification = document.querySelector('#notification-alert-users');
    userTabHeartNotification.classList.add('hidden');

		// Send a request to API to mark user notifications as inactive
		const response = await axios.get(url);
		const { notificationsRemaining } = response.data;

		if (!notificationsRemaining) {
			// Make the red dot disappear if no notifications remaining
			const bottomBarHeartNotification = document.querySelector('#notification-alert-heart-bottom-bar');
			bottomBarHeartNotification.classList.add('hidden');
		}
	}

	if (listNotificationsContainer) {
		// Get user info
		const { id: userId, baseUrl, count } = document.querySelector('.js-variables-container').dataset;
		const notificationsCount = parseInt(count);
		const url = `${baseUrl}/api/v1/notifications/lists/${userId}/read`;

		if (!notificationsCount || notificationsCount === 0) {
			// Return if no active notifications to save some code execution and API request
			return;
		}

		const listTabHeartNotification = document.querySelector('#notification-alert-lists');
		listTabHeartNotification.classList.add('hidden');

		// Send a request to API to mark list notifications as inactive
		const response = await axios.get(url);
		const { notificationsRemaining } = response.data;

		if (!notificationsRemaining) {
			// Make the red dot disappear if no notifications remaining
			const bottomBarHeartNotification = document.querySelector('#notification-alert-heart-bottom-bar');
			bottomBarHeartNotification.classList.add('hidden');
		}
	}

  if (restaurantNotificationsContainer) {
    // Get user info
    const { id: userId, baseUrl, count } = document.querySelector('.js-variables-container').dataset;
    const notificationsCount = parseInt(count);
    const url = `${baseUrl}/api/v1/notifications/restaurants/${userId}/read`;

    if (!notificationsCount || notificationsCount === 0) {
      // Return if no active notifications to save some code execution and API request
      return;
    }

    const restaurantTabHeartNotification = document.querySelector('#notification-alert-restaurants');
    restaurantTabHeartNotification.classList.add('hidden');

    // Send a request to API to mark list notifications as inactive
    const response = await axios.get(url);
    const { notificationsRemaining } = response.data;

    if (!notificationsRemaining) {
      // Make the red dot disappear if no notifications remaining
      const bottomBarHeartNotification = document.querySelector('#notification-alert-heart-bottom-bar');
      bottomBarHeartNotification.classList.add('hidden');
    }
  }
};

export default markNotificationsRead;
