const previewImageOnFileSelect = () => {
	// we select the photo input
	const photoInput = document.getElementById('photo-input');
	if (photoInput) {
		// we add a listener to know when a new picture is uploaded
		photoInput.addEventListener('change', () => {
			// we call the displayPreview function (who retrieve the image url and display it)
			displayPhotoPreview(photoInput);
      displayPhotoPreviewTwo(photoInput);
		});
	}

	const avatarInput = document.getElementById('avatar-input');
	if (avatarInput) {
		// we add a listener to know when a new picture is uploaded
		avatarInput.addEventListener('change', () => {
			// we call the displayPreview function (who retrieve the image url and display it)
			displayAvatarPreview(avatarInput);
		});
	}
};

const displayPhotoPreview = (input) => {
  const photoPlaceholder = document.getElementById('photo-placeholder')

	if (input.files && input.files[0]) {
		const reader = new FileReader();
		reader.onload = (event) => {
			document.getElementById('photo-preview').src = event.currentTarget.result;
		};
		reader.readAsDataURL(input.files[0]);
		document.getElementById('photo-preview').classList.remove('hidden');
    if (photoPlaceholder) {
      document.getElementById('photo-placeholder').classList.add('hidden');
    }
	}
};

const displayPhotoPreviewTwo = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    reader.onload = (event) => {
      document.getElementById('photo-preview-two').src = event.currentTarget.result;
    };
    reader.readAsDataURL(input.files[0]);
    document.getElementById('photo-preview-two').classList.remove('hidden');
  }
};

const displayAvatarPreview = (input) => {
	if (input.files && input.files[0]) {
		const reader = new FileReader();
		reader.onload = (event) => {
			document.getElementById('avatar-preview').src = event.currentTarget.result;
		};
		reader.readAsDataURL(input.files[0]);
		document.getElementById('avatar-preview').classList.remove('hidden');
    document.getElementById('avatar-placeholder').classList.add('hidden');
	}
};

export default previewImageOnFileSelect;
