import axios from 'axios';
import {
	getControllerInputs,
	insertHTML,
	clearHTML,
	updateFilterCounter,
	updateFilterCounterSlider,
} from './helper_functions';
import searchQueryGenerator from '../../shared/search_query_generator';
import checkboxInputsGenerator from './checkbox_inputs_generator';
import createItemsHTML from './create_items_html';

const fetchItems = async (url) => {
	const response = await axios.get(url);
	const data = response.data;
	const html = createItemsHTML(data);
	insertHTML('items', html, 'items');
};

const itemSearchOrchestration = (query) => {
	const { baseUrl, currentLocation } = getControllerInputs();

	let priceUrl = ``;
	const priceCheckbox = document.querySelector('#collapse-price').querySelector("input[type='checkbox']");
	const priceRange = document.querySelector('#collapse-price').querySelector("input[type='range']");
	if (priceCheckbox.checked) {
		priceUrl = `&price=${priceRange.value}`;
	}

	const queryUrl = searchQueryGenerator('item', query);
	const { url: tagsUrl, count: tagsCount } = checkboxInputsGenerator('tags');
	const { url: itemAreaUrl, count: itemAreaCount } = checkboxInputsGenerator('itemarea');
	const locationUrl = searchQueryGenerator('&location', currentLocation);
	const url = `${baseUrl}?${queryUrl}${priceUrl}${tagsUrl}${itemAreaUrl}${locationUrl}`;

	updateFilterCounter('tags', tagsCount);
	updateFilterCounterSlider('price', priceCheckbox.checked, priceRange.value);
	updateFilterCounter('itemarea', itemAreaCount);

	if (query.length === 0 && !priceCheckbox.checked && tagsCount === 0 && itemAreaCount === 0) {
		clearHTML('items', 'items');
	} else {
		fetchItems(url);
	}
};

export default itemSearchOrchestration;
