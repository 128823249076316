const signUpModals = () => {
	const signUpModalActive = document.querySelector('.signUpModalOn');
	const signUpModal = document.querySelector('.signUpModal');

	if (signUpModalActive) {
		const signUpModal = document.querySelector('.signUpModal');
    const signUpModalContent = document.querySelector('.modal-content-sign-in');
		const signUpModalButton = document.querySelectorAll('.signUpModalButton');
		const signUpModalClose = document.querySelector('.signUpModalClose');

		signUpModalButton.forEach((modal) => {
			modal.addEventListener('click', () => {
				signUpModal.classList.remove('hidden');
				document.body.style.overflow = 'hidden';
			});
		});

		signUpModalClose.addEventListener('click', () => {
			signUpModal.classList.add('hidden');
			document.body.style.overflow = 'auto';
		});

    signUpModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!signUpModalContent.contains(event.target)) {
          signUpModal.classList.add('hidden');
          document.body.style.overflow = 'auto';
        }
    });
	}
};

export default signUpModals;
