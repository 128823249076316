import closeModalListener from './functions/body_click_closes_modals';
import removeAllEventListeners from './functions/remove_all_event_listeners';
import { listModalOpenButtonActions, listModalCloseButtonsActions } from './functions/list_modal_button_actions';

const openCloseListModals = () => {
  const followingPostsPage = document.querySelector('.followingPosts');
  const listModalOpenButtonsAllLength = document.querySelectorAll('.listModalButton').length;

  // Guard clause
  if (!followingPostsPage || listModalOpenButtonsAllLength === 0) return;

  closeModalListener();

  const listModalOpenButtonsAllWithListeners = document.querySelectorAll('.listModalButton');
  const listModalCloseButtonsAllWithListeners = document.querySelectorAll('.listModalClose');

  removeAllEventListeners(listModalOpenButtonsAllWithListeners);
  removeAllEventListeners(listModalCloseButtonsAllWithListeners);

  const listModalOpenButtonsAll = document.querySelectorAll('.listModalButton');
  const listModalCloseButtonsAll = document.querySelectorAll('.listModalClose');

  listModalOpenButtonActions(listModalOpenButtonsAll);
  listModalCloseButtonsActions(listModalCloseButtonsAll);
};

export default openCloseListModals;
