const newPostIcon = () => {
  const newPostPresent = document.querySelector(".newPost")

  if (newPostPresent) {
    const cameraIconButton = document.querySelector(".cameraIcon")
    const cameraIconCloseButton = document.querySelector(".cameraIconClose")
    const newPostBox = document.querySelector(".newPostBox")
    const newPostButton = document.querySelector(".newPostButton")
    const newPostModal = document.querySelector(".itemSearchModalNewPost")
    const newPostModalClose = document.querySelector(".cancelSearchIconNewPost")

    const itemSearchButton = document.querySelector(".itemSearchIcon")
    const newPostModalClose2 = document.querySelector(".itemSearchIconCloseNewPost")

    const newPostSearchInput = document.querySelector('#restaurant-show-form-item-for-new-post input[type="text"]');
    const searchClearNewPost = document.querySelector('.search-clear-new-post');


    function simulateBackspace(inputElement) {
      const currentValue = inputElement.value;
      if (currentValue.length > 0) {
        inputElement.value = currentValue.slice(0, -1); // Remove the last character
        inputElement.dispatchEvent(new Event('input', { bubbles: true })); // Trigger input event
        setTimeout(() => simulateBackspace(inputElement)); // Repeat with a delay
      }
    }

    function toggleSearchClearNewPost() {
      if (newPostSearchInput.value.length > 0) {
        searchClearNewPost.style.display = "block";
      } else {
        searchClearNewPost.style.display = "none";
      }
    }

    toggleSearchClearNewPost()

    newPostSearchInput.addEventListener("input", toggleSearchClearNewPost);

    searchClearNewPost.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(newPostSearchInput);
    });

    cameraIconButton.addEventListener('click', function() {
      cameraIconButton.style.display = "none";
      newPostBox.style.display = "flex";
    })

    cameraIconCloseButton.addEventListener('click', function() {
      newPostBox.style.display = "none";
      cameraIconButton.style.display = "flex";
    })

    newPostButton.addEventListener('click', function() {
      cameraIconButton.style.display = "none";
      newPostBox.style.display = "none";
      newPostModal.style.display = "block";
      itemSearchButton.style.display = "none";
      newPostModalClose2.style.display = "block";
    })

    newPostModalClose.addEventListener('click', function() {
      cameraIconButton.style.display = "flex";
      newPostBox.style.display = "none";
      newPostModal.style.display = "none";
      itemSearchButton.style.display = "block";
      newPostModalClose2.style.display = "none";
    })

    newPostModalClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(newPostSearchInput);
    });

    newPostModalClose2.addEventListener('click', function() {
      cameraIconButton.style.display = "flex";
      newPostBox.style.display = "none";
      newPostModal.style.display = "none";
      itemSearchButton.style.display = "block";
      newPostModalClose2.style.display = "none";
    })

    newPostModalClose2.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(newPostSearchInput);
    });


  }
}

const restaurantShowSearch = () => {
  const restaurantBarPresent = document.querySelector(".restaurantBarSearch")

  if (restaurantBarPresent) {
    const itemSearchButton = document.querySelector(".itemSearchIcon")
    const itemSearchCloseButton = document.querySelector(".cancelSearchIcon")
    const itemSearchModal = document.querySelector(".itemSearchModal")

    const cameraIconButton = document.querySelector(".cameraIcon")
    const newPostBox = document.querySelector(".newPostBox")
    const itemSearchCloseButton2 = document.querySelector(".itemSearchIconClose")

    const itemSearchInput = document.querySelector('#restaurant-search-form input[type="text"]');
    const searchClear = document.querySelector('.search-clear-item');

    function simulateBackspace(inputElement) {
      const currentValue = inputElement.value;
      if (currentValue.length > 0) {
        inputElement.value = currentValue.slice(0, -1); // Remove the last character
        inputElement.dispatchEvent(new Event('input', { bubbles: true })); // Trigger input event
        setTimeout(() => simulateBackspace(inputElement)); // Repeat with a delay
      }
    }

    function toggleSearchClear() {
      if (itemSearchInput.value.length > 0) {
        searchClear.style.display = "block";
      } else {
        searchClear.style.display = "none";
      }
    }

    toggleSearchClear()

    itemSearchInput.addEventListener("input", toggleSearchClear);

    searchClear.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(itemSearchInput);
    });

    itemSearchButton.addEventListener('click', function() {
    itemSearchButton.style.display = "none";
    cameraIconButton.style.display = "none";
    newPostBox.style.display = "none";
    itemSearchModal.style.display = "block";
    itemSearchCloseButton2.style.display = "block";
    })

    itemSearchCloseButton.addEventListener('click', function() {
    itemSearchModal.style.display = "none";
    itemSearchButton.style.display = "block";
    cameraIconButton.style.display = "flex";
    itemSearchCloseButton2.style.display = "none";
    })

    itemSearchCloseButton.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(itemSearchInput);
    });

    itemSearchCloseButton2.addEventListener('click', function() {
    itemSearchCloseButton2.style.display = "none";
    itemSearchModal.style.display = "none";
    itemSearchButton.style.display = "block";
    cameraIconButton.style.display = "flex";
    })

    itemSearchCloseButton2.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(itemSearchInput);
    });
  }
}

export { newPostIcon, restaurantShowSearch }
