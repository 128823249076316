import dataInputs from './functions/data_inputs';
import handleFetch from './functions/handle_fetch';
import updatePage from './functions/update_page';

const handleValidation = () => {
	const $handleInput = document.querySelector('#handle-new-edit-input');

	if ($handleInput) {
		const { baseUrl, restaurantId } = dataInputs();
		const initialHandle = $handleInput.value;

		if (initialHandle) {
			handleFetch(baseUrl, initialHandle, restaurantId);
		}

		$handleInput.addEventListener('input', () => {
			const initialHandle = $handleInput.value;
			if (initialHandle.length >= 3) {
				handleFetch(baseUrl, initialHandle, restaurantId);
			} else {
				updatePage('Length is too short');
			}
		});
	}
};

export default handleValidation;
