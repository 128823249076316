require('@rails/ujs').start();
require('turbolinks').start();
require('@rails/activestorage').start();
require('channels');

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// ----------------------------------------------------
// Note(lewagon): ABOVE IS RAILS DEFAULT CONFIGURATION
// WRITE YOUR OWN JS STARTING FROM HERE 👇
// ----------------------------------------------------

// External imports
import 'bootstrap';
import 'controllers';
// import Rails from '@rails/ujs';

// CSS
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
// import 'chartkick/chart.js';
// import ahoy from 'ahoy.js';
import { initSweetalert } from '../plugins/init_sweetalert';

// Internal imports
import initStarRating from '../plugins/init_star_rating';

// Internal imports - DESIGN
import previewImageOnFileSelect from '../components/design/photo_preview';
import changeBackground from '../components/design/body_color';

// Internal imports - TAB NAVIGATION & FORM INACTIVATION/DEACTIVATION
import switchTabs from '../components/tab_navigation_or_forms/switch_discover_tabs';
import disableFormInputs from '../components/tab_navigation_or_forms/disable_form_inputs';
import switchTabsProfile from '../components/tab_navigation_or_forms/user_profile';

// Internal imports - ACTIVITIES
import shareButtonFunction from '../components/activities/share_button';
import { clickChangeActive, clickChangeRestaurantBar } from '../components/activities/click_change';
import { favoritesFollowUnfollow } from '../components/activities/favorites_follow_unfollow';
import markNotificationsRead from '../components/activities/mark_notifications_read';

// Internal imports - FILTERS
import feedFilter from '../components/filters/feed_filter';

// Internal imports - RESTAURANT INDEX SEARCH
import indexSearch from '../components/restaurant_index_search/restaurant_index_search';
import restaurantIndexSearchModal from '../components/restaurant_index_search/restaurant_index_search_modal_control';
import updateTimeDaySliderOutput from '../components/restaurant_index_search/functions/restaurant_index_time_of_day_slider';

// Internal imports - RESTAURANT SHOW SEARCH
import showSearch from '../components/restaurant_show_search/restaurant_show_main_search';
import showNewPostSearch from '../components/restaurant_show_search/restaurant_show_new_post_search';
import restaurantShowFilterModal from '../components/restaurant_show_search/restaurant_show_filter_modal';

// Internal imports - NEW POST SEARCH
import newPostSearch from '../components/new_post_searches/new_post_main_search';

// Internal imports - LISTS on Pages/Home
import listNewModal from '../components/pages_home_lists/list_new_modal';
import listCreateOrchestration from '../components/pages_home_lists/list_create_orchestration';
import addPostToList from '../components/pages_home_lists/add_post_to_list';

// Internal imports - MODALS
import locationModal from '../components/modals/restaurant_index_location_modal';
import delayedModalFade from '../components/modals/delayed_modal_fade';
import copyText from '../components/modals/copy_text';
import closeModal from '../components/modals/close_modal';
import fadeAlertModal from '../components/modals/fade_alert_modal';
import dashboardModals from '../components/modals/dashboard_modals';
import openCloseListModals from '../components/pages_home_lists/open_close_list_modals';
import postModalsRestaurant from '../components/modals/post_modals_restaurant';
import reportModals from '../components/modals/report_modals';

// Internal imports - client-side validation
import usernameValidation from '../components/profiles_user/client_username_validation';
import handleValidation from '../components/profiles_restaurant/client_handle_validation';

// Internal imports - reviews related views
import reviewClickThroughProgression from '../components/reviews/review_click_through_progression';
import restaurantReview from '../components/reviews/restaurant_review'

import {
  ambienceModals,
  itemPhotoModals,
  postPhotoModals,
  restaurantPhotoModals,
} from '../components/modals/restaurant_show_modals';
import signUpModals from '../components/modals/sign_in_modal';
import { newPostIcon, restaurantShowSearch } from '../components/modals/search_modal';
import newPostSearchModal from '../components/modals/new_post';

// Internal imports - Manager Dashboard
import featurePostActive from '../components/manager_dashboard/feature_post';
import viewedReport from '../components/manager_dashboard/viewed_report';
import featureGalleryFavorites from '../components/manager_dashboard/gallery_favorites';

// Internal imports - User Dashboard
import userDashboardClickChange from '../components/user_dashboard/user_dashboard_click_change';

// Internal imports - Home Follow Unfollow
import homeFollowUnfollow from '../components/activities/home_follow_unfollow.js';

// Internal imports - Restaurant Info
import restaurantInfo from '../components/activities/restaurant_info.js';

// Internal imports - List Show
import listShow from '../components/activities/list_show.js';

// Notification.requestPermission().then(function (result) {});

// Call functions here
document.addEventListener('turbolinks:load', () => {
  changeBackground();
  initStarRating();
  previewImageOnFileSelect();
  indexSearch();
  showSearch();
  disableFormInputs();
  usernameValidation();
  handleValidation();
  initSweetalert();
  shareButtonFunction();
  delayedModalFade();
  copyText();
  closeModal();
  switchTabs();
  dashboardModals();
  fadeAlertModal();
  openCloseListModals();
  postModalsRestaurant();
  addPostToList();
  clickChangeActive();
  updateTimeDaySliderOutput();
  ambienceModals();
  itemPhotoModals();
  listNewModal();
  listCreateOrchestration();
  clickChangeRestaurantBar();
  signUpModals();
  postPhotoModals();
  newPostIcon();
  restaurantShowSearch();
  restaurantPhotoModals();
  switchTabsProfile();
  restaurantIndexSearchModal();
  feedFilter();
  restaurantShowFilterModal();
  newPostSearchModal();
  locationModal();
  favoritesFollowUnfollow();
  showNewPostSearch();
  newPostSearch();
  reportModals();
  reviewClickThroughProgression();
  featurePostActive();
  viewedReport();
  markNotificationsRead();
  featureGalleryFavorites();
  restaurantReview();
  userDashboardClickChange();
  homeFollowUnfollow();
  restaurantInfo();
  listShow();
});
