import axios from 'axios';

import updatePage from './update_page';

const handleFetch = async (baseUrl, handle, restaurantId) => {
	let url = `${baseUrl}/api/v1/restaurants/validate?handle=${handle}`;
	if (typeof restaurantId !== 'undefined') {
		url = `${baseUrl}/api/v1/restaurants/validate?handle=${handle}&id=${restaurantId}`;
	}

	const response = await axios.get(url);
	const {
		data: { status },
	} = response;
	updatePage(status);
};

export default handleFetch;
