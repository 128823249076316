const listModalOpenButtonActions = (buttonList) => {
  buttonList.forEach((button) => {
    const postModal = button.parentNode.parentNode.querySelector('.listModal');
    button.classList.add('listener-added');

    button.addEventListener('click', () => {
      postModal.classList.remove('hidden');
      document.body.style.overflow = 'hidden';
    });
  });
};

const listModalCloseButtonsActions = (buttonList) => {
  buttonList.forEach((button) => {
    button.classList.add('listener-added');

    const postModal = button.parentNode.parentNode;

    button.addEventListener('click', () => {
      postModal.classList.add('hidden');
      document.body.style.overflow = 'auto';
    });
  });
};

export { listModalOpenButtonActions, listModalCloseButtonsActions };
