const dataInputs = () => {
	const baseUrl = document.querySelector('.js-variables-container').dataset.url.trim();

	let restaurantId = '';
	restaurantId = document.querySelector('.js-variables-container').dataset.restaurantId;
	if (restaurantId) {
		restaurantId = restaurantId.trim();
	}

	return { baseUrl, restaurantId };
};

export default dataInputs;
