const updateFilterCounter = (filterType, count) => {
	const counterButton = document.querySelector(`#${filterType}-counter-button-restaurant-show`);
	const counter = counterButton.querySelector('span');

	if (count > 0) {
		counter.innerText = count;
		counterButton.style.display = 'inline-block';
	} else {
		counterButton.style.display = 'none';
	}
};

const updateFilterCounterSlider = (filterType, checked, value) => {
	const counterButton = document.querySelector(`#${filterType}-counter-button-restaurant-show`);
	const counter = counterButton.querySelector('span');

	if (checked) {
		counter.innerText = value;
		counterButton.style.display = 'inline-block';
	} else {
		counterButton.style.display = 'none';
	}
};

export { updateFilterCounter, updateFilterCounterSlider };
