const restaurantReview = () => {
  const newReviewPage = document.querySelector('#restaurant-review-page');

  if (newReviewPage) {

    const nextOne = document.querySelector('.nextOne');
    const firstModal = document.querySelector('.firstModal');

    const backOne = document.querySelector('.backOne');
    const captionButton = document.querySelector('.captionButton');

    const nextTwo = document.querySelector('.nextTwo');
    const secondModal = document.querySelector('.secondModal');

    const backTwo = document.querySelector('.backTwo');
    const thirdModal = document.querySelector('.thirdModal');

    // const orderType = document.querySelector('.orderType');
    // const servingTime = document.querySelector('.servingTime');
    // const portionSize = document.querySelector('.portionSize');
    // const overallService = document.querySelector('.overallService');
    // const privateFeedback = document.querySelector('.privateFeedback');
    // const postButton = document.querySelector('.postButton');

    captionButton.addEventListener('click', () => {
      nextTwo.style.display = 'block';
    });

    nextOne.addEventListener('click', () => {
      firstModal.style.display = 'none';
      secondModal.style.display = 'block';
    });

    backOne.addEventListener('click', () => {
      firstModal.style.display = 'block';
      secondModal.style.display = 'none';
    });

    nextTwo.addEventListener('click', () => {
      secondModal.style.display = 'none';
      thirdModal.style.display = 'block';
    });

    backTwo.addEventListener('click', () => {
      secondModal.style.display = 'block';
      thirdModal.style.display = 'none';
    });

    $('input[type=checkbox]').change(function(e){
       if ($('input[type=checkbox]:checked').length > 3) {
            $(this).prop('checked', false)
       }
    })

    // orderType.addEventListener('click', () => {
    //   servingTime.style.display = 'block';
    // });

    // servingTime.addEventListener('click', () => {
    //   portionSize.style.display = 'block';
    // });

    // portionSize.addEventListener('click', () => {
    //   overallService.style.display = 'block';
    // });

    // overallService.addEventListener('click', () => {
    //   privateFeedback.style.display = 'block';
    //   postButton.style.display = 'block';
    // });
  }
};

export default restaurantReview;
