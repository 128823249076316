import listHtmlCreator from './functions/list_html_creator';
import postPostsData from './functions/post_posts_data';

const listCreateOrchestration = () => {
  const submitButttons = document.querySelectorAll(
    '.create-list-submit-button:not(.listener-added)'
  );

  if (submitButttons) {
    const rootDiv = document.querySelector('#fb-root');
    const { webDomain, token } = rootDiv ? rootDiv.dataset : '';

    submitButttons.forEach((button) => {
      button.classList.add('listener-added');

      button.addEventListener('click', async () => {
        const listForm = button.parentNode.parentNode;
        const listContainer = button.parentNode.parentNode.parentNode.parentNode;
        const listGridContainer = listContainer.parentNode.querySelector(
          '.list-grid-horizontal-scroll'
        );

        const nameInput = listForm.querySelector('.list_name_input');
        const descriptionInput = listForm.querySelector('.list_name_description');
        const privInput = listForm.querySelector('.list_name_private');

        const name = nameInput.value;
        const description = descriptionInput.value;
        const priv = privInput.checked;
        const itemId = listForm.dataset.itemId;

        const url = `${webDomain}/api/v1/lists`;
        const data = await postPostsData(url, name, description, priv, itemId, token);

        if (data.saved) {
          // Clear text-danger warning
          listContainer.querySelector('.text-danger').innerText = '';

          // Toggle hidden so the form disappears and clear input values
          listContainer.classList.toggle('hidden');
          nameInput.value = '';
          descriptionInput.value = '';
          privInput.checked = false;

          // Insert html for new list item into the modal
          const html = listHtmlCreator(data.item_photo_key, data.list_name);
          listGridContainer.insertAdjacentHTML('afterbegin', html);
        } else {
          listContainer.querySelector('.text-danger').innerText = data.errors;
        }
      });
    });
  }
};

export default listCreateOrchestration;
