const newPostSearchModal = () => {
	const newPostPage = document.querySelector('#new-post-page');

	if (newPostPage) {
		const newPostSearchBox = document.querySelector('#new-post-search-box');
		const newPostSearchBoxClose = document.querySelector('#new-post-search-box-close');
		const newPostSearchModal = document.querySelector('#new-post-search-modal');

    const searchInput = document.querySelector('#new-post-search-box input[type="text"]');
    const searchClear = document.querySelector('.search-clear');

    function simulateBackspace(inputElement) {
      const currentValue = inputElement.value;
      if (currentValue.length > 0) {
        inputElement.value = currentValue.slice(0, -1); // Remove the last character
        inputElement.dispatchEvent(new Event('input', { bubbles: true })); // Trigger input event
        setTimeout(() => simulateBackspace(inputElement)); // Repeat with a delay
      }
    }

    function toggleSearchClear() {
      if (searchInput.value.length > 0) {
        searchClear.style.display = "block";
      } else {
        searchClear.style.display = "none";
      }
    }

    toggleSearchClear()

    searchInput.addEventListener("input", toggleSearchClear);

    searchClear.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(searchInput);
    });


    newPostSearchBox.addEventListener('click', () => {
      newPostSearchBoxClose.style.display = 'block';
      newPostSearchModal.style.display = 'block';
    });

    newPostSearchBoxClose.addEventListener('click', () => {
      newPostSearchBoxClose.style.display = 'none';
      newPostSearchModal.style.display = 'none';
    });

    newPostSearchBoxClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(searchInput);
    });

	}
};

export default newPostSearchModal;
