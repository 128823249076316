// Function to update the output value of the time of day slider and display as a time
import { timeMinsToString } from './helper_functions';

const updateTimeDaySliderOutput = () => {
	const timeDayModalDiv = document.querySelector('#collapse-time');

	if (timeDayModalDiv) {
		const sliderInput = timeDayModalDiv.querySelector('.slidecontainer').querySelector('input');
		const sliderOutput = timeDayModalDiv.querySelector('.slidecontainer').querySelector('output');

		sliderInput.addEventListener('input', () => {
			const minsPastMidnight = sliderInput.value;
			sliderOutput.value = timeMinsToString(minsPastMidnight);
		});
	}
};

export default updateTimeDaySliderOutput;
