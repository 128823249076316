const closeAllModals = () => {
  const postModals = document.querySelectorAll('.listModal');

  postModals.forEach((postModal) => {
    if (!postModal.classList.contains('hidden')) {
      postModal.classList.add('hidden');
      document.body.style.overflow = 'auto';
    }
  });
};

const closeAllModalsLogic = (event) => {
  const anyModalClicked = Array.from(document.querySelectorAll('.modal-content-add-to-list')).some(
    (element) => element.contains(event.target)
  );

  const anyButtonClicked = Array.from(document.querySelectorAll('.listModalButton')).some(
    (element) => element.contains(event.target)
  );

  if (anyModalClicked || anyButtonClicked) {
    return;
  }

  closeAllModals();
};

const closeModalListener = () => {
  document.body.addEventListener('click', closeAllModalsLogic);
};

export default closeModalListener;
