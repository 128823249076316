const ambienceModals = () => {
  const restaurantCoverPhotoPage = document.querySelector("#restaurantCoverPhoto")

  if (restaurantCoverPhotoPage) {
    const ambienceModalButton = document.querySelector(".ambienceModalButton")
    const ambienceModalClose = document.querySelector(".ambienceModalClose")
    const ambienceModal = document.querySelector(".ambienceModal")

    const restaurantCategoryContainer = document.querySelector(".restaurant-show-titles-container")

      ambienceModalButton.addEventListener('click', function() {
      ambienceModal.style.display = "block";
      document.body.style.overflow = "hidden";
      console.log('Scrolled all the way to the right');
      })

      ambienceModalClose.addEventListener('click', function() {
      ambienceModal.style.display = "none";
      document.body.style.overflow = "auto";
      })

    restaurantCategoryContainer.addEventListener('scroll', function() {
        let maxScrollLeft = this.scrollWidth - this.clientWidth;

        if (restaurantCategoryContainer.scrollLeft >= maxScrollLeft) {
            // Remove the fade effect
            restaurantCategoryContainer.classList.remove('mask-image');
        } else {
            // Reapply the fade effect if not at the end
            restaurantCategoryContainer.classList.add('mask-image');
        }
    });

    // function isScrolledToRight(element) {
    //     var maxScrollLeft = element.scrollWidth - element.clientWidth;
    //     // Check if the current scroll position is equal to or greater than the maximum scroll left
    //     return element.scrollLeft >= maxScrollLeft;
    // }

    // restaurantCategoryContainer.addEventListener('scroll', function() {
    //     if (isScrolledToRight(restaurantCategoryContainer)) {
    //       restaurantCategoryContainer.classList.remove('mask-image');
    //     } else {
    //       restaurantCategoryContainer.classList.add('mask-image');
    //     }
    // });

  }
}

const itemPhotoModals = () => {
  const itemPhotoPresent = document.querySelector("#itemPhoto")
  const itemPhotoPresentFavorites = document.querySelector("#itemPhotoFavorites")

  if (itemPhotoPresent) {
    const photoButton = document.querySelectorAll(".itemPhotoButton")
    const photoClose = document.querySelectorAll(".itemPhotoClose")
    let countOpen = 0
    let countClose = 0

    photoButton.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModal")[countOpen]
      countOpen = countOpen + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "block";
      document.body.style.overflow = "hidden";
      })
    })

    photoClose.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModal")[countClose]
      countClose = countClose + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "none";
      document.body.style.overflow = "auto";
      })
    })
  }

  if (itemPhotoPresent) {
    const photoButtonGallery = document.querySelectorAll(".itemPhotoButtonGallery")
    const photoCloseGallery = document.querySelectorAll(".itemPhotoCloseGallery")
    let countOpen = 0
    let countClose = 0

    photoButtonGallery.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModalGallery")[countOpen]
      countOpen = countOpen + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "block";
      document.body.style.overflow = "hidden";
      })
    })

    photoCloseGallery.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModalGallery")[countClose]
      countClose = countClose + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "none";
      document.body.style.overflow = "auto";
      })
    })
  }

  if (itemPhotoPresentFavorites) {
    const photoButtonFavorites = document.querySelectorAll(".itemPhotoButtonFavorites")
    const photoCloseFavorites = document.querySelectorAll(".itemPhotoCloseFavorites")
    let countOpen = 0
    let countClose = 0

    photoButtonFavorites.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModalFavorites")[countOpen]
      countOpen = countOpen + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "block";
      document.body.style.overflow = "hidden";
      })
    })

    photoCloseFavorites.forEach( item => {
      let photoModal = document.querySelectorAll(".itemPhotoModalFavorites")[countClose]
      countClose = countClose + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "none";
      document.body.style.overflow = "auto";
      })
    })
  }



}

const postPhotoModals = () => {
  const postPhotoPresent = document.querySelector(".postPhotoAvailable")

  if (postPhotoPresent) {
    const photoButton = document.querySelectorAll(".postPhotoButton")
    const photoClose = document.querySelectorAll(".postPhotoClose")
    let countOpen = 0
    let countClose = 0

    photoButton.forEach( item => {
      let photoModal = document.querySelectorAll(".postPhotoModal")[countOpen]
      countOpen = countOpen + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "block";
      document.body.style.overflow = "hidden";
      })
    })

    photoClose.forEach( item => {
      let photoModal = document.querySelectorAll(".postPhotoModal")[countClose]
      countClose = countClose + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "none";
      document.body.style.overflow = "auto";
      })
    })
  }
}

const restaurantPhotoModals = () => {
  const restaurantPhotoPresent = document.querySelector(".restaurantPhotoAvailable")

  if (restaurantPhotoPresent) {
    const photoButton = document.querySelectorAll(".restaurantPhotoButton")
    const photoClose = document.querySelectorAll(".restaurantPhotoClose")
    let countOpen = 0
    let countClose = 0

    photoButton.forEach( item => {
      let photoModal = document.querySelectorAll(".restaurantPhotoModal")[countOpen]
      countOpen = countOpen + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "block";
      document.body.style.overflow = "hidden";
      })
    })

    photoClose.forEach( item => {
      let photoModal = document.querySelectorAll(".restaurantPhotoModal")[countClose]
      countClose = countClose + 1
      item.addEventListener('click', function() {
      photoModal.style.display = "none";
      document.body.style.overflow = "auto";
      })
    })
  }
}




export { ambienceModals, itemPhotoModals, postPhotoModals, restaurantPhotoModals }
