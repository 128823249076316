import restaurantItemsSearchOrchestration from './functions/restaurant_show_new_post_functions';
import preventFormSubmit from '../shared/prevent_form_submit';

let timerId;
const debounce = (callback, delay) => {
	clearTimeout(timerId);
	timerId = setTimeout(callback, delay);
};

const showNewPostSearch = () => {
	const searchForm = document.querySelector('#restaurant-show-form-item-for-new-post');
	const searchInput = document.querySelector('#restaurant-show-input-item-for-new-post');

	// Prevent enter press on form activating a http request
	if (searchForm) {
		preventFormSubmit(searchForm);
	}

	// User input in search bar triggers search (with debounce delay)
	if (searchInput) {
		searchInput.addEventListener('input', (event) => {
			debounce(() => restaurantItemsSearchOrchestration(searchInput.value), 500);
		});
	}
};

export default showNewPostSearch;
