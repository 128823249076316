import axios from 'axios';
import { getControllerInputs, insertHTML, clearHTML } from './helper_functions';
import searchQueryGenerator from '../../shared/search_query_generator';

const createUserHTML = (data) => {
	let html = '';

	data.forEach((user) => {
    if (!user.hidden && !user.blocked) {
  		let img_str = '';

  		if (user.photo_key.length > 0) {
  			img_str = `<img src="https://res.cloudinary.com/thiaqo/image/upload/${user.photo_key}" loading="lazy">`;
  		} else {
        img_str = `<img src="https://res.cloudinary.com/thiaqo/image/upload/v1661905769/margot/no_profile_pic_2_ukcgxv.png" loading="lazy">`;
      }

      if (user.name.length > 0) {
    		html += `
          <div class="">
            <a href="${user.url}">
              <div class="card-small-user">
                <div class="card-small-img-avatar">
                  ${img_str}
                </div>
                <div class="card-small-info-user-search">
                  <h2>${user.username}</h2>
                  <p>${user.name}</p>
                </div>
              </div>
            </a>
          </div>
        `;
      } else {
        html += `
          <div class="">
            <a href="${user.url}">
              <div class="card-small-user">
                <div class="card-small-img-avatar">
                  ${img_str}
                </div>
                <div class="card-small-info-user-search">
                  <h2>${user.username}</h2>
                </div>
              </div>
            </a>
          </div>
        `;
      }
    }
	});

	return html;
};

const fetchUsers = async (url) => {
	const response = await axios.get(url);
	const data = response.data;
	const html = createUserHTML(data);
	insertHTML('users', html);
};

const userSearchOrchestration = (query) => {
	const { baseUrl, currentLocation, username } = getControllerInputs();

	const queryUrl = searchQueryGenerator('user', query);
	// const locationUrl = searchQueryGenerator('&location', currentLocation);
	const usernameUrl = username ? searchQueryGenerator('&username', username) : '';
	// const url = `${baseUrl}?${queryUrl}${locationUrl}${usernameUrl}`;
	const url = `${baseUrl}?${queryUrl}${usernameUrl}`;

	if (query.length === 0) {
		clearHTML('users');
	} else {
		fetchUsers(url);
	}
};

export default userSearchOrchestration;
