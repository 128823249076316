const switchTabsProfile = () => {
	const tabsContainer = document.querySelector('.profile-gallery-lists');

	if (tabsContainer) {
		const galleryTab = tabsContainer.querySelector('#user-gallery-tab');
		const listsTab = tabsContainer.querySelector('#user-lists-tab');

		const galleryContent = document.querySelector('#user-gallery-tab-content');
		const listsContent = document.querySelector('#user-lists-tab-content');

		galleryTab.addEventListener('click', () => {
			galleryTab.classList.add('profile-active');
			galleryTab.classList.remove('profile-inactive');
			listsTab.classList.remove('profile-active');
			listsTab.classList.add('profile-inactive');

			galleryContent.style.display = 'block';
			listsContent.style.display = 'none';
		});

		listsTab.addEventListener('click', () => {
			galleryTab.classList.remove('profile-active');
			galleryTab.classList.add('profile-inactive');
			listsTab.classList.add('profile-active');
			listsTab.classList.remove('profile-inactive');

			galleryContent.style.display = 'none';
			listsContent.style.display = 'block';
		});
	}
};

export default switchTabsProfile;
