const insertHTML = (searchDiv, html, recentDiv = null) => {
	const searchContainer = document.querySelector(`#restaurant-index-${searchDiv}-container`);
	if (recentDiv) {
		const recentContainer = document.querySelector(`#restaurant-index-recent-${recentDiv}-container`);
		recentContainer.style.display = 'none';
	}
	searchContainer.innerHTML = html;
	searchContainer.style.display = 'block';
};

const clearHTML = (searchDiv, recentDiv = null) => {
	const searchContainer = document.querySelector(`#restaurant-index-${searchDiv}-container`);
	searchContainer.innerHTML = '';
	searchContainer.style.display = 'none';
	if (recentDiv) {
		const recentContainer = document.querySelector(`#restaurant-index-recent-${recentDiv}-container`);
		recentContainer.style.display = 'block';
	}
};

// Function to convert time in minutes into a formatted time string
const timeMinsToString = (mins) => {
	const timeMins = mins % 60 === 0 ? '00' : mins % 60;

	let timeHours = '';
	let amPM = '';

	if (mins < 59) {
		timeHours = 12;
		amPM = 'am';
	// } else if (mins === 720) {
		// timeHours = 12;
		// amPM = 'pm';
  // } else if (mins === 1440) {
    // timeHours = 12;
    // amPM = 'am';
  } else if (mins > 1430) {
    timeHours = 12;
    amPM = 'am';
	} else if (mins > 779) {
		timeHours = Math.floor((mins - 720) / 60);
		amPM = 'pm';
  } else if (mins > 700) {
    timeHours = 12;
    amPM = 'pm';
	} else {
		timeHours = Math.floor(mins / 60);
		amPM = 'am';
	}

	return `${timeHours}:${timeMins}${amPM}`;
};

const getControllerInputs = () => {
	const restaurantIndexPage = document.querySelector('#restaurant-index');
	const { baseUrl, currentLocation, username } = restaurantIndexPage.dataset;
	return restaurantIndexPage ? { baseUrl, currentLocation, username } : {};
};

const updateFilterCounter = (filterType, count) => {
	const counterButton = document.querySelector(`#${filterType}-counter-button`);
	const counter = counterButton.querySelector('span');

	if (count > 0) {
		counter.innerText = count;
		counterButton.style.display = 'inline-block';
	} else {
		counterButton.style.display = 'none';
	}
};

const updateFilterCounterSlider = (filterType, checked, value) => {
	const counterButton = document.querySelector(`#${filterType}-counter-button`);
	const counter = counterButton.querySelector('span');

	if (checked) {
		counter.innerText = value;
		counterButton.style.display = 'inline-block';
	} else {
		counterButton.style.display = 'none';
	}
};

export { insertHTML, clearHTML, timeMinsToString, getControllerInputs, updateFilterCounter, updateFilterCounterSlider };
