const closeModal = () => {
	const modal = document.querySelector('#wifi-modal');
	const closeButton = document.querySelector('#close-wifi-button');

	if (modal) {
		closeButton.addEventListener('click', () => {
			modal.style.display = 'none';
		});
	}
};

export default closeModal;
