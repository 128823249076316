import axios from 'axios';
import {
	getControllerInputs,
	insertHTML,
	clearHTML,
	timeMinsToString,
	updateFilterCounter,
	updateFilterCounterSlider,
} from './helper_functions';
import searchQueryGenerator from '../../shared/search_query_generator';
import checkboxInputsGenerator from './checkbox_inputs_generator';

const createDealHTML = (data) => {
	let html = '';

	data.forEach((deal) => {
    if (!deal.hidden) {
  		let img_str = '';

      if (deal.photo_key.length > 0) {
        img_str = `<img src="https://res.cloudinary.com/thiaqo/image/upload/${deal.photo_key}" loading="lazy">`;
      }

      if (deal.photo_key.length > 0) {
    		html += `
          <div class="col-xs-12 col-xl-4">
            <a href="${deal.url}">
              <div class="card-small-item">
                <div class="card-small-img">
                  ${img_str}
                </div>
                <div class="card-small-info">
                  <h2>${deal.name}</h2>
                  <p>${deal.restaurant} ⋅ ${deal.locale} ⋅ ${deal.active_days_short}</p>
                </div>
              </div>
            </a>
          </div>
        `;
      } else {
        html += `
          <div class="col-xs-12 col-xl-4">
            <a href="${deal.url}">
              <div class="card-small-item">
                <div class="card-small-info">
                  <h2>${deal.name}</h2>
                  <p>${deal.restaurant} ⋅ ${deal.locale} ⋅ ${deal.active_days_short}</p>
                </div>
              </div>
            </a>
          </div>
        `;
      }
    }
	});

	return html;
};

const fetchDeals = async (url) => {
	const response = await axios.get(url);
	const data = response.data;
	const html = createDealHTML(data);
	insertHTML('deals', html);
};

const dealSearchOrchestration = (query) => {
	const { baseUrl, currentLocation } = getControllerInputs();

	let timeUrl = ``;
	const timeCheckbox = document.querySelector('#collapse-time').querySelector("input[type='checkbox']");
	const timeRange = document.querySelector('#collapse-time').querySelector("input[type='range']");
	if (timeCheckbox.checked) {
		timeUrl = `&time=${timeRange.value}`;
	}

	const queryUrl = searchQueryGenerator('deal', query);
	const { url: daysUrl, count: daysCount } = checkboxInputsGenerator('days');
	const { url: dealAreaUrl, count: dealAreaCount } = checkboxInputsGenerator('dealarea');
	const locationUrl = searchQueryGenerator('&location', currentLocation);
	const url = `${baseUrl}?${queryUrl}${daysUrl}${timeUrl}${dealAreaUrl}${locationUrl}`;

	updateFilterCounter('days', daysCount);
	updateFilterCounterSlider('time', timeCheckbox.checked, timeMinsToString(timeRange.value));
	updateFilterCounter('dealarea', dealAreaCount);

	if (query.length === 0 && daysCount === 0 && !timeCheckbox.checked && dealAreaCount === 0) {
		clearHTML('deals');
	} else {
		fetchDeals(url);
	}
};

export default dealSearchOrchestration;
