const reviewClickThroughProgression = () => {
	const newPostPage = document.querySelector('#review-page');
  const multipleLocale = document.querySelector('#multiple-locale');

	if (newPostPage) {

		const newPostIntro = document.querySelector('.newPostIntro');
		const photoButton = document.querySelector('.photoButton');
		const nextOne = document.querySelector('.nextOne');
		const firstModal = document.querySelector('.firstModal');

    const photoButtonLabel = document.querySelector('.btn-re-submit-picture');
    const photoButtonDirections = document.querySelector('.choose-type');

		const backOne = document.querySelector('.backOne');
		const captionButton = document.querySelector('.captionButton');
		const nextTwo = document.querySelector('.nextTwo');
		const secondModal = document.querySelector('.secondModal');

		const backTwo = document.querySelector('.backTwo');
    const thirdModal = document.querySelector('.thirdModal');

		const orderType = document.querySelector('.orderType');
    const orderTypeLabels = orderType.querySelectorAll('.form-check');
		const servingTime = document.querySelector('.servingTime');
    const servingTimeLabels = servingTime.querySelectorAll('.form-check');
		const portionSize = document.querySelector('.portionSize');
    const portionSizeLabels = portionSize.querySelectorAll('.form-check');
		const overallService = document.querySelector('.overallService');
    const overallServiceLabels = overallService.querySelectorAll('.form-check');

		const privateFeedback = document.querySelector('.privateFeedback');
		const postButton = document.querySelector('.postButton');

    if (multipleLocale) {
      const orderLocale = document.querySelector('.orderLocale');
      const nextLocale = document.querySelector('.nextLocale');
      const backLocale = document.querySelector('.backLocale');
      const localeModal = document.querySelector('.localeModal');

      orderLocale.addEventListener('click', () => {
        nextLocale.style.display = 'block';
      });

      nextLocale.addEventListener('click', () => {
        localeModal.style.display = 'none';
        firstModal.style.display = 'block';
      });

      backLocale.addEventListener('click', () => {
        localeModal.style.display = 'block';
        firstModal.style.display = 'none';
      });
    }

		photoButton.addEventListener('click', () => {
			newPostIntro.style.display = 'none';
      photoButtonDirections.style.display = 'none';
			nextOne.style.display = 'block';
      photoButtonLabel.textContent = 'Change photo';
		});

		captionButton.addEventListener('click', () => {
			nextTwo.style.display = 'block';
		});

		nextOne.addEventListener('click', () => {
			firstModal.style.display = 'none';
      secondModal.style.display = 'block';
		});

		backOne.addEventListener('click', () => {
			firstModal.style.display = 'block';
      secondModal.style.display = 'none';
		});

		nextTwo.addEventListener('click', () => {
			secondModal.style.display = 'none';
      thirdModal.style.display = 'block';
		});

		backTwo.addEventListener('click', () => {
			secondModal.style.display = 'block';
      thirdModal.style.display = 'none';
		});

		// orderType.addEventListener('click', () => {
		// 	servingTime.style.display = 'block';
		// });

    orderTypeLabels.forEach((label) => {
      label.addEventListener('click', () => {
        servingTime.style.display = 'block';
      });
    });

		// servingTime.addEventListener('click', () => {
		// 	portionSize.style.display = 'block';
		// });

    servingTimeLabels.forEach((label) => {
      label.addEventListener('click', () => {
        portionSize.style.display = 'block';
      });
    });

		// portionSize.addEventListener('click', () => {
		// 	overallService.style.display = 'block';
		// });

    portionSizeLabels.forEach((label) => {
      label.addEventListener('click', () => {
        overallService.style.display = 'block';
      });
    });

		// overallService.addEventListener('click', () => {
		// 	privateFeedback.style.display = 'block';
		// 	postButton.style.display = 'block';
		// });

    overallServiceLabels.forEach((label) => {
      label.addEventListener('click', () => {
        privateFeedback.style.display = 'block';
        postButton.style.display = 'block';
      });
    });

	}
};

export default reviewClickThroughProgression;
