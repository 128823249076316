import dataInputs from './functions/data_inputs';
import usernameFetch from './functions/username_fetch';
import updatePage from './functions/update_page';

const usernameValidation = () => {
	const $usernameEditInput = document.querySelector('#username-edit-input');

	if ($usernameEditInput) {
		const { baseUrl, userId } = dataInputs();
		const initialUsername = $usernameEditInput.value;

		usernameFetch(baseUrl, initialUsername, userId);

		$usernameEditInput.addEventListener('input', () => {
			const username = $usernameEditInput.value;
			if (username.length >= 3) {
				usernameFetch(baseUrl, username, userId);
			} else {
				updatePage('Length is too short');
			}
		});
	}
};

export default usernameValidation;
