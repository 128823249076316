const shareButtonFunction = () => {
	const rootDiv = document.querySelector('#fb-root');
	const { webDomain } = rootDiv ? rootDiv.dataset : '';
	const shareButton = document.querySelector('#share-button:not(.listener-added)');
	const itemShareButtons = document.querySelectorAll('.item-share-button:not(.listener-added)');
	const itemShareButtonsPosts = document.querySelectorAll('.item-share-button-post:not(.listener-added)');

	if (shareButton) {
		shareButton.classList.add('listener-added');

		const title = document.title;
		const url = document.querySelector('link[rel=canonical]')
			? document.querySelector('link[rel=canonical]').href
			: document.location.href;

		shareButton.addEventListener('click', () => {
			if (navigator.share) {
				navigator.share({
					title: title,
					url: url,
				});
			}
		});
	}

	if (itemShareButtons.length > 0) {
		const restaurantName = document.querySelector('#js-variables-container').dataset.restaurantName;
		// const hostname = document.location.hostname;
		// const port = document.location.port;
		// const baseUrl = port.length > 0 ? `${hostname}:${port}` : `${hostname}`;

		itemShareButtons.forEach((button) => {
			button.classList.add('listener-added');

			const itemName = button.dataset.itemName;
			const itemToken = button.dataset.itemToken;

			const title = `${itemName} by ${restaurantName} on Margot`;
			const url = `${webDomain}/items/${itemToken}`;

			button.addEventListener('click', () => {
				if (navigator.share) {
					navigator.share({
						title: title,
						url: url,
					});
				}
			});
		});
	}

	if (itemShareButtonsPosts.length > 0) {
		// const hostname = document.location.hostname;
		// const port = document.location.port;
		// const baseUrl = port.length > 0 ? `${hostname}:${port}` : `${hostname}`;

		itemShareButtonsPosts.forEach((button) => {
			button.classList.add('listener-added');

			const itemName = button.dataset.itemName;
			const restaurantName = button.dataset.restaurantName;
			const itemToken = button.dataset.itemToken;

			const title = `${itemName} by ${restaurantName} on Margot`;
			const url = `${webDomain}/items/${itemToken}`;

			button.addEventListener('click', () => {
				if (navigator.share) {
					navigator.share({
						title: title,
						url: url,
					});
				}
			});
		});
	}
};

export default shareButtonFunction;
