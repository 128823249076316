const createItemsNewPostRestaurantShowHTML = (data) => {
  let html = '';

  data.forEach((item) => {
    if (!item.hidden) {
      let img_str = '';
      let item_price = '';

      if (item.price > 0) {
        item_price = `⋅ ${item.price} <span>USD</span>`
      }

      if (item.photo_key.length > 0) {
        img_str = `<img src="https://res.cloudinary.com/thiaqo/image/upload/${item.photo_key}" loading="lazy">`;
      }

      if (item.photo_key.length > 0) {
        html += `
          <div class="col-xs-12 col-xl-4">
            <a href="${item.new_post_url}">
              <div class="card-small-item">
                <div class="card-small-img">
                  ${img_str}
                </div>
                <div class="card-small-info">
                  <h2>${item.name}</h2>
                  <p>${item.category} ⋅ ${item.subcategory} ${item_price}</p>
                </div>
              </div>
            </a>
          </div>
        `;
      } else {
        html += `
          <div class="col-xs-12 col-xl-4">
            <a href="${item.new_post_url}">
              <div class="card-small-item">
                <div class="card-small-info">
                  <h2>${item.name}</h2>
                  <p>${item.category} ⋅ ${item.subcategory} ${item_price}</p>
                </div>
              </div>
            </a>
          </div>
        `;
      }
    }
  });

  return html;
};

export default createItemsNewPostRestaurantShowHTML;
