const featureGalleryFavorites = () => {
  const editItemPage = document.querySelector('.editItemPage');
  const galleryFeature = document.querySelector('.featureGallery')
  const favoritesFeature = document.querySelector('.featureFavorites')
  const galleryRemove = document.querySelector('.removeGallery')
  const favoritesRemove = document.querySelector('.removeFavorites')
  const galleryPosition = document.querySelector('.positionGallery')
  const favoritesPosition = document.querySelector('.positionFavorites')

  if (editItemPage) {

    galleryFeature.addEventListener('click', () => {
      galleryFeature.style.display = 'none';
      galleryRemove.style.display = 'block';
      galleryPosition.style.display = 'block';
    });

    galleryRemove.addEventListener('click', () => {
      galleryFeature.style.display = 'block';
      galleryRemove.style.display = 'none';
      galleryPosition.style.display = 'none';
    });

    favoritesFeature.addEventListener('click', () => {
      favoritesFeature.style.display = 'none';
      favoritesRemove.style.display = 'block';
      favoritesPosition.style.display = 'block';
    });

    favoritesRemove.addEventListener('click', () => {
      favoritesFeature.style.display = 'block';
      favoritesRemove.style.display = 'none';
      favoritesPosition.style.display = 'none';
    });

  };
};

export default featureGalleryFavorites;
