const clickChangeActive = () => {
	const clickChangeActivePage = document.querySelector('.clickChangeActivate');
	const heartEmptyShow = document.querySelectorAll('.heart-empty-show');
	const heartPinkShow = document.querySelectorAll('.heart-pink-show');
	const heartEmptyHidden = document.querySelectorAll('.heart-empty-hidden');
	const heartPinkHidden = document.querySelectorAll('.heart-pink-hidden');
	let countHeartEmptyShow = 0;
	let countHeartPinkShow = 0;
	let countHeartEmptyHidden = 0;
	let countHeartPinkHidden = 0;

	if (clickChangeActivePage) {
		heartEmptyShow.forEach((emptyHeart) => {
			let thisHeartEmpty = document.querySelectorAll('.heart-empty-show')[countHeartEmptyShow];
			let thisHeartPink = document.querySelectorAll('.heart-pink-hidden')[countHeartEmptyShow];
			countHeartEmptyShow = countHeartEmptyShow + 1;
			emptyHeart.addEventListener('click', function () {
				thisHeartEmpty.style.display = 'none';
				thisHeartPink.style.display = 'block';
			});
		});

		heartPinkHidden.forEach((pinkHeart) => {
			let thisHeartEmpty = document.querySelectorAll('.heart-empty-show')[countHeartPinkHidden];
			let thisHeartPink = document.querySelectorAll('.heart-pink-hidden')[countHeartPinkHidden];
			countHeartPinkHidden = countHeartPinkHidden + 1;
			pinkHeart.addEventListener('click', function () {
				thisHeartPink.style.display = 'none';
				thisHeartEmpty.style.display = 'block';
			});
		});

		heartPinkShow.forEach((pinkHeart) => {
			let thisHeartEmpty = document.querySelectorAll('.heart-empty-hidden')[countHeartPinkShow];
			let thisHeartPink = document.querySelectorAll('.heart-pink-show')[countHeartPinkShow];
			countHeartPinkShow = countHeartPinkShow + 1;
			pinkHeart.addEventListener('click', function () {
				thisHeartPink.style.display = 'none';
				thisHeartEmpty.style.display = 'block';
			});
		});

		heartEmptyHidden.forEach((emptyHeart) => {
			let thisHeartEmpty = document.querySelectorAll('.heart-empty-hidden')[countHeartEmptyHidden];
			let thisHeartPink = document.querySelectorAll('.heart-pink-show')[countHeartEmptyHidden];
			countHeartEmptyHidden = countHeartEmptyHidden + 1;
			emptyHeart.addEventListener('click', function () {
				thisHeartEmpty.style.display = 'none';
				thisHeartPink.style.display = 'block';
			});
		});
	}
};

const clickChangeRestaurantBar = () => {
	const restaurantBarPresent = document.querySelector('.restaurantBar');
	const currentFollowing = document.querySelector('.followingShow');
	const currentFollow = document.querySelector('.followShow');
  const followRequest = document.querySelector('.followRequest');
  const suggestedUsers = document.querySelector('.suggestedUsers');

  if (suggestedUsers) {
    const suggestedUsersShow = document.querySelectorAll('.suggestedUsersShow');
    const suggestedUsersHide = document.querySelector('.suggestedUsersHide');

    suggestedUsersShow.forEach((button) => {
      button.addEventListener('click', function () {
        suggestedUsers.style.display = 'block';
      });
    });

    suggestedUsersHide.addEventListener('click', function () {
      suggestedUsers.style.display = 'none';
    });

  }

	if (currentFollow) {
		const showFollow = document.querySelectorAll('.followButton');
		const hiddenFollowing = document.querySelectorAll('.unfollowButtonOpen');
    const hideFollowing = document.querySelector('.unfollowButton');

		showFollow.forEach((button) => {
      button.addEventListener('click', function () {
  			showFollow.forEach(function(element) {
            element.style.display = 'none';
        });
  			hiddenFollowing.forEach(function(element) {
            element.style.display = 'block';
        });
  		});
    });

		hideFollowing.addEventListener('click', function () {
      showFollow.forEach(function(element) {
          element.style.display = 'block';
      });
      hiddenFollowing.forEach(function(element) {
          element.style.display = 'none';
      });
		});
	}

	if (currentFollowing) {
		const showFollowing = document.querySelectorAll('.unfollowButtonOpen');
		const hiddenFollow = document.querySelectorAll('.followButton');
    const hideFollowing = document.querySelector('.unfollowButton');

		hideFollowing.addEventListener('click', function () {
      showFollowing.forEach(function(element) {
          element.style.display = 'none';
      });
      hiddenFollow.forEach(function(element) {
          element.style.display = 'block';
      });
		});

		hiddenFollow.forEach((button) => {
      button.addEventListener('click', function () {
        showFollowing.forEach(function(element) {
            element.style.display = 'block';
        });
        hiddenFollow.forEach(function(element) {
            element.style.display = 'none';
        });
  		});
    });
	}

	if (restaurantBarPresent) {
    const barHeader = document.querySelector('.restaurant-bar-header');
		const dropdownArrow = document.querySelector('.restaurant-bar-dropdown-arrow');
		const dropdownArrowUp = document.querySelector('.restaurant-bar-dropdown-arrow-up');
    const restaurantHours = document.querySelector('.todays-hours');
		const hoursDropdownDown = document.querySelector('.opening-hours-dropdown-arrow');
		const hoursDropdownUp = document.querySelector('.opening-hours-dropdown-arrow-up');

    barHeader.addEventListener('click', function() {
        // Select the dropdown arrow elements

        // Toggle the display of both arrows
        if (dropdownArrow && dropdownArrowUp) {
            // If the dropdown arrow is visible, hide it and show the up arrow
            if (dropdownArrow.style.display !== 'none') {
                dropdownArrow.style.display = 'none';
                dropdownArrowUp.style.display = 'block';
            } else {
                // If the dropdown arrow is hidden, show it and hide the up arrow
                dropdownArrow.style.display = 'block';
                dropdownArrowUp.style.display = 'none';
            }
        }
    });

    restaurantHours.addEventListener('click', function() {
        // Select the dropdown arrow elements

        // Toggle the display of both arrows
        if (hoursDropdownDown && hoursDropdownUp) {
            // If the dropdown arrow is visible, hide it and show the up arrow
            if (hoursDropdownDown.style.display !== 'none') {
                hoursDropdownDown.style.display = 'none';
                hoursDropdownUp.style.display = 'block';
            } else {
                // If the dropdown arrow is hidden, show it and hide the up arrow
                hoursDropdownDown.style.display = 'block';
                hoursDropdownUp.style.display = 'none';
            }
        }
    });
	}

  if (followRequest) {
    const confirmRequest = document.querySelectorAll('.confirmRequest');
    const denyRequest = document.querySelectorAll('.denyRequest');

    confirmRequest.forEach((button) => {
      const showFollowUnfollow = button.parentNode.parentNode.querySelector('.showFollowUnfollow');
      const hideRequestButtons = button.parentNode.parentNode.querySelector('.hideRequestButtons');

      button.addEventListener('click', function () {
        hideRequestButtons.style.display = 'none';
        showFollowUnfollow.style.display = 'block';
      });
    });

    denyRequest.forEach((button) => {
      const hideRequest = button.parentNode.parentNode.parentNode.querySelector('.hideRequest');

      button.addEventListener('click', function () {
        hideRequest.style.display = 'none';
      });
    });

  }
};


export { clickChangeActive, clickChangeRestaurantBar };
