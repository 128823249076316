import newPostControllerInputs from './new_post_controller_inputs';
import searchQueryGenerator from '../../shared/search_query_generator';
import fetchData from '../../shared/fetch_data';
import createItemsNewPostHTML from '../../restaurant_show_search/functions/create_items_new_post_html';

// Clear HTML Fucntions
const clearPageHtml = () => {
	const itemsContainer = document.querySelector('#new-post-items-container');
	itemsContainer.innerHTML = '';
};

const insertItemsHTML = (html) => {
	const itemsContainer = document.querySelector('#new-post-items-container');
	itemsContainer.innerHTML = html;
};

const newPostSearchOrcestration = async (query) => {
	const { baseUrl, currentLocation } = newPostControllerInputs();
	const queryUrl = searchQueryGenerator('item', query);
	const locationUrl = searchQueryGenerator('&location', currentLocation);

	const url = `${baseUrl}/new_post?${queryUrl}${locationUrl}`;

	if (query.length === 0) {
		clearPageHtml();
	} else {
		const data = await fetchData(url);
		const html = createItemsNewPostHTML(data);
		insertItemsHTML(html);
	}
};

export default newPostSearchOrcestration;
