import { enableSubmitButton, disableSubmitButton } from './enable_disable_submit_button';

const updatePage = (status) => {
	const $usernameStatus = document.querySelector('#username-validation-status');

	if (status === 'AVAILABLE') {
		$usernameStatus.innerText = status;
		$usernameStatus.style.color = '#5cb85c';
		enableSubmitButton();
	} else {
		$usernameStatus.innerText = status;
		$usernameStatus.style.color = '#ff0000';
		disableSubmitButton();
	}
};

export default updatePage;
