// Function to open locations modal
const locationModal = () => {
	const restaurantIndex = document.querySelector('#restaurant-index');
  const newPostPage = document.querySelector('#new-post-page');

	if (restaurantIndex) {
		const locationModalButton = document.querySelector('#location-modal-button');
		const locationsModal = document.querySelector('#location-modal');
    const locationsModalContent = document.querySelector('.locationModalContent');
		const locationModalClose = document.querySelector('#location-modal-close');

		locationModalButton.addEventListener('click', () => {
			locationsModal.style.display = 'block';
			document.body.style.overflow = 'hidden';
		});

		locationModalClose.addEventListener('click', () => {
			locationsModal.style.display = 'none';
			document.body.style.overflow = 'auto';
		});

    locationsModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!locationsModalContent.contains(event.target)) {
          locationsModal.style.display = 'none';
          document.body.style.overflow = 'auto';
        }
    });
	}

  if (newPostPage) {
    const locationModalButtonNewPost = document.querySelector('#location-modal-button');
    const locationsModalNewPost = document.querySelector('#location-modal');
    const locationsModalContent = document.querySelector('.locationModalContent');
    const locationModalCloseNewPost = document.querySelector('#location-modal-close');

    locationModalButtonNewPost.addEventListener('click', () => {
      locationsModalNewPost.style.display = 'block';
      document.body.style.overflow = 'hidden';
    });

    locationModalCloseNewPost.addEventListener('click', () => {
      locationsModalNewPost.style.display = 'none';
      document.body.style.overflow = 'auto';
    });

    locationsModalNewPost.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!locationsModalContent.contains(event.target)) {
          locationsModalNewPost.style.display = 'none';
          document.body.style.overflow = 'auto';
        }
    });
  }
};

export default locationModal;
