const postModalsRestaurant = () => {
	const followingPostsPage = document.querySelector('.followingPosts');

	if (followingPostsPage) {
		const postModalButtonRestaurant = document.querySelectorAll('.listModalButtonRestaurant');
		const postModalCloseRestaurant = document.querySelectorAll('.listModalCloseRestaurant');
		let countOpen = 0;
		let countClose = 0;

		postModalButtonRestaurant.forEach((post) => {
			let postModal = document.querySelectorAll('.listModalRestaurant')[countOpen];
			countOpen = countOpen + 1;
			post.addEventListener('click', function () {
				postModal.style.display = 'block';
				document.body.style.overflow = 'hidden';
			});
		});

		postModalCloseRestaurant.forEach((post) => {
			let postModal = document.querySelectorAll('.listModalRestaurant')[countClose];
			countClose = countClose + 1;
			post.addEventListener('click', function () {
				postModal.style.display = 'none';
				document.body.style.overflow = 'auto';
			});
		});
	}
};

export default postModalsRestaurant;
