// Internal imports
import preventFormSubmit from '../shared/prevent_form_submit';
import restaurantSearchOrchestration from './functions/restaurant_functions';
import itemSearchOrchestration from './functions/item_functions';
import dealSearchOrchestration from './functions/deal_functions';
import userSearchOrchestration from './functions/user_functions';

let timerId;
const debounce = (callback, delay) => {
	clearTimeout(timerId);
	timerId = setTimeout(callback, delay);
};

const indexSearch = () => {
	const restaurantsSearchForm = document.querySelector('#index-search-form-restaurants');
	const itemsSearchForm = document.querySelector('#index-search-form-items');
	const dealsSearchForm = document.querySelector('#index-search-form-deals');
	const usersSearchForm = document.querySelector('#index-search-form-users');

	const filterCountercloseButtons = document.querySelectorAll('.close-button-small');

	const restaurantsContent = document.querySelector('#discover-restaurants-tab-content');
	const searchRestaurants = document.querySelector('#restaurant-index-search-restaurants');
	const restaurantModal = document.querySelector('#restaurantFilterModal');
  const restaurantModalContent = document.querySelector('#restaurantModalContent');
	const restaurantFiltersConfirmButton = document.querySelector('#restaurant-filters-confirm-button');

	const itemsContent = document.querySelector('#discover-items-tab-content');
	const searchItems = document.querySelector('#restaurant-index-search-items');
	const itemFiltersConfirmButton = document.querySelector('#item-filters-confirm-button');

	const dealsContent = document.querySelector('#discover-deals-tab-content');
	const searchDeals = document.querySelector('#restaurant-index-search-deals');
	const dealFiltersConfirmButton = document.querySelector('#deal-filters-confirm-button');

	// const usersContent = document.querySelector('#discover-users-tab-content');
	const searchUsers = document.querySelector('#restaurant-index-search-users');

	// Prevent form submission
	if (restaurantsSearchForm) {
		preventFormSubmit(restaurantsSearchForm);
	}

	if (itemsSearchForm) {
		preventFormSubmit(itemsSearchForm);
	}

	if (dealsSearchForm) {
		preventFormSubmit(dealsSearchForm);
	}

	if (usersSearchForm) {
		preventFormSubmit(usersSearchForm);
	}

	if (searchRestaurants) {
		searchRestaurants.addEventListener('input', (event) => {
			debounce(() => restaurantSearchOrchestration(searchRestaurants.value), 500);
		});
	}

	if (searchItems) {
		searchItems.addEventListener('input', (event) => {
			debounce(() => itemSearchOrchestration(searchItems.value), 500);
		});
	}

	if (searchDeals) {
		searchDeals.addEventListener('input', (event) => {
			debounce(() => dealSearchOrchestration(searchDeals.value), 500);
		});
	}

	if (searchUsers) {
		searchUsers.addEventListener('input', (event) => {
			debounce(() => userSearchOrchestration(searchUsers.value), 500);
		});
	}

	if (restaurantFiltersConfirmButton) {
		restaurantFiltersConfirmButton.addEventListener('click', () => {
			restaurantSearchOrchestration(searchRestaurants.value);

			restaurantModal.classList.remove('visible');
		});
	}

	if (itemFiltersConfirmButton) {
		itemFiltersConfirmButton.addEventListener('click', () => {
			itemSearchOrchestration(searchItems.value);

			const filterModal = document.getElementById('itemFilterModal');
			filterModal.classList.remove('visible');
		});
	}

	if (dealFiltersConfirmButton) {
		dealFiltersConfirmButton.addEventListener('click', () => {
			dealSearchOrchestration(searchDeals.value);

			const filterModal = document.getElementById('dealFilterModal');
			filterModal.classList.remove('visible');
		});
	}

	if (filterCountercloseButtons.length > 0) {
		filterCountercloseButtons.forEach((closeButton) => {
			closeButton.addEventListener('click', (event) => {
				const closeButtonDiv = event.target.parentNode; // Parent button to the x image
				const filterType = closeButtonDiv.id.split('-')[0]; // the word up until the first "-" in the id of the button
				const checkboxes = document.querySelector(`#collapse-${filterType}`).querySelectorAll("input[type='checkbox']"); // All checkboxes in accompanying modal

				checkboxes.forEach((checkbox) => {
					checkbox.checked = false;
				});

				closeButtonDiv.style.display = 'none';

				// Need some if logic here to determine which search to call
				if (restaurantsContent.style.display !== 'none') {
					restaurantSearchOrchestration(searchRestaurants.value);
				} else if (itemsContent.style.display !== 'none') {
					itemSearchOrchestration(searchItems.value);
				} else if (dealsContent.style.display !== 'none') {
					dealSearchOrchestration(searchDeals.value);
				}
			});
		});
	}

	if (restaurantModal) {
		const restaurantModalShow = document.querySelector('#restaurantFilterModalButton');
		const restaurantCloseImg = document.querySelector('.restaurant-filter-modal-close').querySelector('img');

		const itemModal = document.querySelector('#itemFilterModal');
    const itemModalContent = document.querySelector('.itemFilterModalContent');
		const itemModalShow = document.querySelector('#itemFilterModalButton');
		const itemCloseImg = document.querySelector('.item-filter-modal-close').querySelector('img');

		const dealModal = document.querySelector('#dealFilterModal');
    const dealModalContent = document.querySelector('.dealFilterModalContent');
		const dealModalShow = document.getElementById('dealFilterModalButton');
		const dealCloseImg = document.querySelector('.deal-filter-modal-close').querySelector('img');

		restaurantModalShow.addEventListener('click', () => {
			restaurantModal.classList.add('visible');
		});

		restaurantCloseImg.addEventListener('click', () => {
			restaurantModal.classList.remove('visible');
			restaurantSearchOrchestration(searchRestaurants.value);
		});

		itemModalShow.addEventListener('click', () => {
			itemModal.classList.add('visible');
		});

		itemCloseImg.addEventListener('click', () => {
			itemModal.classList.remove('visible');
			itemSearchOrchestration(searchItems.value);
		});

		dealModalShow.addEventListener('click', () => {
			dealModal.classList.add('visible');
		});

		dealCloseImg.addEventListener('click', () => {
			dealModal.classList.remove('visible');
			dealSearchOrchestration(searchDeals.value);
		});

    restaurantModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!restaurantModalContent.contains(event.target)) {
          restaurantModal.classList.remove('visible');
        }
    });

    itemModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!itemModalContent.contains(event.target)) {
          itemModal.classList.remove('visible');
        }
    });

    dealModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!dealModalContent.contains(event.target)) {
          dealModal.classList.remove('visible');
        }
    });
	}
};

export default indexSearch;
