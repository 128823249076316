import preventFormSubmit from '../shared/prevent_form_submit';
import newPostSearchOrcestration from './functions/new_post_main_search_orchestration';

let timerId;
const debounce = (callback, delay) => {
	clearTimeout(timerId);
	timerId = setTimeout(callback, delay);
};

// Orchestrates all combinations of user actions on page that could result in a search being activated
const newPostSearch = () => {
	const form = document.querySelector('#new-post-search-form');
	const formInput = document.querySelector('#new-post-search-input');

	if (form) {
		preventFormSubmit(form);
	}

	// User input in search bar triggers search (with debounce delay)
	if (formInput) {
		formInput.addEventListener('input', (event) => {
			debounce(() => newPostSearchOrcestration(formInput.value), 500);
		});
	}
};

export default newPostSearch;
