const listHtmlCreator = (photo_key, list_name) => {
	return `<div class="list-box">
			<div class="list-cover-image">
				<img src="http://res.cloudinary.com/thiaqo/image/upload/c_fill/${photo_key}">
			</div>
			<p>${list_name}</p>
		</div>`;
};

export default listHtmlCreator;
