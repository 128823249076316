const favoritesFollowUnfollow = () => {
  const unfollowAvailable = document.querySelectorAll(".unfollowShowFavorites")
  const showUnfollowFollower = document.querySelectorAll(".unfollowShowFavoritesFollower")
  const showFollowFollower = document.querySelectorAll(".followShowFavoritesFollower")
  const removeFollowerAvailable = document.querySelector(".removeFollowerAvailable")
  let showUnfollowCount = 0
  let showUnfollowFollowerCount = 0
  let showFollowFollowerCount = 0

  if (unfollowAvailable) {
    const showUnfollow = document.querySelectorAll(".unfollow-button-show")

    showUnfollow.forEach( showUnfollowButton => {
      let hiddenFollow = document.querySelectorAll(".follow-button-hidden")[showUnfollowCount]
      showUnfollowCount = showUnfollowCount + 1

      showUnfollowButton.addEventListener('click', function () {
      showUnfollowButton.style.display = "none";
      hiddenFollow.style.display = "block";
      })

      hiddenFollow.addEventListener('click', function () {
        showUnfollowButton.style.display = "block";
        hiddenFollow.style.display = "none";
      })
    })

  }

  if (showUnfollowFollower) {

    showUnfollowFollower.forEach( showUnfollowButtonFollower => {
      let hiddenFollowFollower = document.querySelectorAll(".follow-button-hidden-follower")[showUnfollowFollowerCount]
      showUnfollowFollowerCount = showUnfollowFollowerCount + 1

      showUnfollowButtonFollower.addEventListener('click', function () {
        showUnfollowButtonFollower.style.display = "none";
        hiddenFollowFollower.style.display = "block";
      })

      hiddenFollowFollower.addEventListener('click', function () {
        showUnfollowButtonFollower.style.display = "block";
        hiddenFollowFollower.style.display = "none";
      })
    })
  }

  if (showFollowFollower) {

    showFollowFollower.forEach( showFollowButtonFollower => {
      let hiddenUnfollowFollower = document.querySelectorAll(".unfollow-button-hidden-follower")[showFollowFollowerCount]
      showFollowFollowerCount = showFollowFollowerCount + 1

      showFollowButtonFollower.addEventListener('click', function () {
        showFollowButtonFollower.style.display = "none";
        hiddenUnfollowFollower.style.display = "block";
      })

      hiddenUnfollowFollower.addEventListener('click', function () {
        showFollowButtonFollower.style.display = "block";
        hiddenUnfollowFollower.style.display = "none";
      })
    })

  }

  if (removeFollowerAvailable) {
    const removeFollowerOpen = document.querySelectorAll(".removeFollowerOpen")
    const removeFollowerClose = document.querySelectorAll(".removeFollowerClose")
    const removeFollower = document.querySelectorAll(".removeFollower")

    removeFollowerOpen.forEach((button) => {
      const removeFollowerModal = button.parentNode.querySelector('.removeFollowerModal');
      const removeFollowerModalContent = button.parentNode.querySelector('#removeFollowerModalContent');

      button.addEventListener('click', function () {
        removeFollowerModal.style.display = 'block';
      });

      removeFollowerModal.addEventListener('click', function(event) {
          // Check if the clicked element is the excluded div or a descendant of it
          if (!removeFollowerModalContent.contains(event.target)) {
            removeFollowerModal.style.display = "none";
          }
      });
    });

    removeFollowerClose.forEach((button) => {
      const removeFollowerModal = button.parentNode.parentNode.parentNode.parentNode.querySelector('.removeFollowerModal');

      button.addEventListener('click', function () {
        removeFollowerModal.style.display = 'none';
      });
    });

    removeFollower.forEach((button) => {
      const removeFollowerModal = button.parentNode.parentNode.parentNode.parentNode.querySelector('.removeFollowerModal');
      const removedFollower = button.parentNode.parentNode.parentNode.parentNode.querySelector('.removedFollower');
      const thisRemoveFollowerOpen = button.parentNode.parentNode.parentNode.parentNode.querySelector('.removeFollowerOpen');

      button.addEventListener('click', function () {
        removeFollowerModal.style.display = 'none';
        thisRemoveFollowerOpen.style.display = 'none';
        removedFollower.style.display = 'block';
      });
    });

  }

}

export { favoritesFollowUnfollow }
