const openingHoursContainer = document.querySelector('#opening-hours-container');

const updateCheckbox = (checkbox) => {
	const rowTimeInputs = checkbox.parentNode.parentNode.parentNode.querySelectorAll("input[type='time']");

	if (checkbox.checked) {
		rowTimeInputs.forEach((input) => input.removeAttribute('disabled'));
	} else {
		rowTimeInputs.forEach((input) => input.setAttribute('disabled', 'true'));
	}
};

const disableFormInputs = () => {
	if (openingHoursContainer) {
		const checkboxes = openingHoursContainer.querySelectorAll("input[type='checkbox']");

		checkboxes.forEach((checkbox) => {
			updateCheckbox(checkbox);
			checkbox.addEventListener('click', () => {
				updateCheckbox(checkbox);
			});
		});
	}
};

export default disableFormInputs;
