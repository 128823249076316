const restaurantInfo = () => {
  const restaurantInfoDesktop = document.querySelector('.restaurant-info-desktop');

  if (restaurantInfoDesktop) {
    const followPage = document.querySelector('.followPage');

    const arrowBox = document.querySelector('.arrowBox');
    const arrowDown = document.querySelector('.opening-hours-dropdown-arrow-desktop');
    const arrowUp = document.querySelector('.opening-hours-dropdown-arrow-up-desktop');

    arrowBox.addEventListener('click', function () {
      let ariaExpanded = arrowBox.getAttribute('aria-expanded');

      if (ariaExpanded === 'true') {
        arrowUp.style.display = 'none';
        arrowDown.style.display = 'block';
      } else if (ariaExpanded === 'false') {
        arrowDown.style.display = 'none';
        arrowUp.style.display = 'block';
      }
    });


    if (followPage) {
      const followRestaurant = document.querySelector('.follow-restaurant-desktop');
      const unfollowButtonOpen = document.querySelector('.unfollowButtonOpen');
      const unfollowModal = document.querySelector('.unfollowModal');
      const unfollowModalContent = document.querySelector('.unfollowModalContent');
      const unfollowButton = document.querySelector('.unfollowButton');
      const unfollowCloseModalDesktop = document.querySelectorAll('.unfollowCloseModalDesktop');

      const successFollowFlash = document.querySelector('.successFollow');
      const successUnfollowFlash = document.querySelector('.successUnfollow');

      followRestaurant.addEventListener('click', function () {
        followRestaurant.style.display = 'none';
        unfollowButtonOpen.style.display = 'block';
        successFollowFlash.classList.add('success-flash-pop');
        setTimeout(fadeSuccessFollowFlash, 4000);
      });

      unfollowButtonOpen.addEventListener('click', function () {
        unfollowModal.style.display = 'block';
      });

      unfollowCloseModalDesktop.forEach((button) => {
        button.addEventListener('click', function() {
        unfollowModal.style.display = "none";
        });
      });

      unfollowButton.addEventListener('click', function() {
        unfollowButtonOpen.style.display = 'none';
        followRestaurant.style.display = 'block';
        unfollowModal.style.display = 'none';
        successUnfollowFlash.classList.add('success-flash-pop');
        setTimeout(fadeSuccessUnfollowFlash, 4000);
      });

      unfollowModal.addEventListener('click', function(event) {
          // Check if the clicked element is the excluded div or a descendant of it
          if (!unfollowModalContent.contains(event.target)) {
            unfollowModal.style.display = "none";
          }
      });

      const fadeSuccessFollowFlash = () => {
        successFollowFlash.classList.remove('success-flash-pop');
      };

      const fadeSuccessUnfollowFlash = () => {
        successUnfollowFlash.classList.remove('success-flash-pop');
      };
    }
  }
};

export default restaurantInfo;
