import restaurantShowControllerInputs from './restaurant_show_controller_inputs';
import searchQueryGenerator from '../../shared/search_query_generator';
import checkboxInputsGenerator from '../../restaurant_index_search/functions/checkbox_inputs_generator';
import { updateFilterCounter, updateFilterCounterSlider } from './update_filter_counters_restaurant_show';
import fetchData from '../../shared/fetch_data';
import createItemsRestaurantShowHTML from '../../restaurant_index_search/functions/create_items_rs_html';

// Clear HTML Functions
const clearPageHtml = () => {
	const itemsContainer = document.querySelector('#restaurant-show-items-container');
	itemsContainer.innerHTML = '';
};

const insertItemsHTML = (html) => {
	const itemsContainer = document.querySelector('#restaurant-show-items-container');
	itemsContainer.innerHTML = html;
};

const restaurantItemsSearchOrchestration = async (query) => {
	const { baseUrl, restaurantHandle } = restaurantShowControllerInputs();
	const queryUrl = searchQueryGenerator('item', query);

	let priceUrl = ``;
	const priceCheckbox = document.querySelector('#collapse-price').querySelector("input[type='checkbox']");
	const priceRange = document.querySelector('#collapse-price').querySelector("input[type='range']");
	if (priceCheckbox.checked) {
		priceUrl = `&price=${priceRange.value}`;
	}

  const tagsPresent = document.querySelector('#restaurantShowTags');

  if (tagsPresent) {
  	const { url: tagsUrl, count: tagsCount } = checkboxInputsGenerator('tags');
  	const url = `${baseUrl}/${restaurantHandle}?${queryUrl}${priceUrl}${tagsUrl}`;

  	updateFilterCounter('tags', tagsCount);
  	updateFilterCounterSlider('price', priceCheckbox.checked, priceRange.value);

  	if (query.length === 0 && !priceCheckbox.checked && tagsCount === 0) {
  		clearPageHtml();
  	} else {
  		const data = await fetchData(url);
  		const html = createItemsRestaurantShowHTML(data);
  		insertItemsHTML(html);
  	}
  } else {
    const url = `${baseUrl}/${restaurantHandle}?${queryUrl}${priceUrl}`;

    updateFilterCounterSlider('price', priceCheckbox.checked, priceRange.value);

    if (query.length === 0 && !priceCheckbox.checked ) {
      clearPageHtml();
    } else {
      const data = await fetchData(url);
      const html = createItemsRestaurantShowHTML(data);
      insertItemsHTML(html);
    }
  }

};

export default restaurantItemsSearchOrchestration;
