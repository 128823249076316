const disableSubmitButton = () => {
	const submitButton = document.querySelector('#restaurant-new-edit-submit');
	submitButton.disabled = true;
};

const enableSubmitButton = () => {
	const submitButton = document.querySelector('#restaurant-new-edit-submit');
	submitButton.disabled = false;
};

export { enableSubmitButton, disableSubmitButton };
