const changeBackground = () => {
	const restaurantShow = document.querySelector('.paper-white');
	const blackBackground = document.querySelector('.black-background');
  const noAccessPage = document.querySelector('#no-access-page');
	// const setLocation = document.querySelector("#set-location")

	if (restaurantShow) {
		document.body.style.backgroundColor = 'white';
		window.addEventListener('load', function () {
			changeBackground('white');
		});
	}

	if (blackBackground) {
		document.body.style.backgroundColor = 'black';
		// window.addEventListener("load",function() { changeBackground('$cool-pink') });
	}

  if (noAccessPage) {
    const bottomBar = document.querySelector('#bottom-bar');
    // const navBar = document.querySelector('#navbar');

    if (bottomBar) {
      document.querySelector('#bottom-bar').style.display = "none";
    }

    // if (navBar) {
    //   document.querySelector('#navbar').style.display = "none";
    // }

  }

	// if (setLocation) {
	//   const locations = document.querySelectorAll(".change-background-color")

	//   locations.forEach( location => {
	//     location.addEventListener('click', function () {
	//       document.body.style.backgroundColor = "white";
	//     })
	//   })

	// }
};

export default changeBackground;
