import axios from 'axios';
import { getControllerInputs, insertHTML, clearHTML, updateFilterCounter } from './helper_functions';
import searchQueryGenerator from '../../shared/search_query_generator';
import checkboxInputsGenerator from './checkbox_inputs_generator';

const createRestaurantHTML = (data) => {
	let html = '';

	data.forEach((restaurant) => {
    if (!restaurant.hidden) {
  		let img_str = '';

  		if (restaurant.avatar_key.length > 0) {
  			img_str = `<img src="https://res.cloudinary.com/thiaqo/image/upload/${restaurant.avatar_key} " loading="lazy">`;
  		}

  		html += `
        <div class="col-xs-12 col-xl-4">
          <a href="${restaurant.url}">
            <div class="card-small-restaurant">
              <div class="card-small-img">
                ${img_str}
              </div>
              <div class="card-small-info">
                <h2>${restaurant.name}</h2>
                <p>${restaurant.cuisine} ⋅ ${restaurant.locale}</p>
              </div>
            </div>
          </a>
        </div>
      `;
    }
	});

	return html;
};

const fetchRestaurants = async (url) => {
	const response = await axios.get(url);
	const data = response.data;
	const html = createRestaurantHTML(data);
	insertHTML('restaurants', html, 'restaurants');
};

const restaurantSearchOrchestration = (query) => {
	const { baseUrl, currentLocation } = getControllerInputs();
	const queryUrl = searchQueryGenerator('restaurant', query);
	const { url: cuisineUrl, count: cuisineCount } = checkboxInputsGenerator('cuisine');
	const { url: localeUrl, count: localeCount } = checkboxInputsGenerator('locale');
	const { url: localeParentUrl, count: localeParentCount } = checkboxInputsGenerator('localeparent');
	const locationUrl = searchQueryGenerator('&location', currentLocation);
	const url = `${baseUrl}?${queryUrl}${cuisineUrl}${localeUrl}${localeParentUrl}${locationUrl}`;

	updateFilterCounter('cuisine', cuisineCount);
	updateFilterCounter('locale', localeCount);
	updateFilterCounter('localeparent', localeParentCount);

	if (query.length === 0 && cuisineCount === 0 && localeCount === 0 && localeParentCount === 0) {
		clearHTML('restaurants', 'restaurants');
	} else {
		fetchRestaurants(url);
	}
};

export default restaurantSearchOrchestration;
