const listShow = () => {
  const listShow = document.querySelector('#list-show');
  const editListPhoto = document.querySelector('.new-list-form-img')

  if (listShow) {
    const controlPrev = document.querySelectorAll('.carousel-control-prev');
    const controlNext = document.querySelectorAll('.carousel-control-next');

    controlNext.forEach((button) => {
      const controlPrevSpec = button.parentNode.querySelector('.carousel-control-prev');

      button.addEventListener('click', function () {
        button.style.display = 'none';
        controlPrevSpec.style.display = 'flex';
      });
    });

    controlPrev.forEach((button) => {
      const controlNextSpec = button.parentNode.querySelector('.carousel-control-next');

      button.addEventListener('click', function () {
        button.style.display = 'none';
        controlNextSpec.style.display = 'flex';
      });
    });

  }

  if (editListPhoto) {
    const changePhotoButton = document.querySelector('.change-cover-photo-button')

    changePhotoButton.addEventListener('click', function () {
      editListPhoto.style.display = 'none';
    });
  }


  //   $(document).ready(function(){
  //       // Initial arrow visibility
  //       $('.carousel-control-prev').hide(); // Hide the previous arrow initially
  //       $('.carousel-control-next').show(); // Show the next arrow initially

  //       // Rest of the event handling code...
  //   });

  //   $(document).ready(function(){
  //       $('#myCarousel').on('slide.bs.carousel', function (e) {
  //           var activeItem = $(e.relatedTarget); // The item that is being slid to
  //           var index = activeItem.index(); // Index of that item

  //           // If the new slide is the first slide (index 0)
  //           if (index === 0) {
  //               $('.carousel-control-prev').hide(); // Hide the previous arrow
  //               $('.carousel-control-next').show(); // Show the next arrow
  //           }
  //           // If the new slide is the second slide
  //           else if (index === 1) {
  //               $('.carousel-control-next').hide(); // Hide the next arrow
  //               $('.carousel-control-prev').show(); // Show the previous arrow
  //           }
  //       });
  //   });
  // }
};

export default listShow;
