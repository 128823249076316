import consumer from './consumer';

consumer.subscriptions.create('UserChannel', {
	received(data) {
		const bottomBarHeartNotification = document.querySelector('#notification-alert-heart-bottom-bar');
		// const listTabHeartNotification = document.querySelector('#notification-alert-lists');

		if (bottomBarHeartNotification && data.notification) {
			const alertCircle = bottomBarHeartNotification.querySelector('.alert-circle');

			if (alertCircle) {
				return;
			}

			bottomBarHeartNotification.insertAdjacentHTML('afterbegin', '<div class="alert-circle"></div>');
		}

		// if (listTabHeartNotification && data.notification) {
		// 	const alertCircle = listTabHeartNotification.querySelector('.alert-circle');

		// 	if (alertCircle) {
		// 		return;
		// 	}

		// 	listTabHeartNotification.insertAdjacentHTML('afterbegin', '<div class="alert-circle"></div>');
		// }
	},
});
