// Controls which modals are shown in the discover / restaurant-index page
const restaurantIndexSearchModal = () => {
	const tabsContainer = document.querySelector('#discover-navigation-tabs');

	if (tabsContainer) {
		const restaurantsSearchBox = document.querySelector('#restaurant-search-box');
		const itemsSearchBox = document.querySelector('#item-search-box');
		const dealsSearchBox = document.querySelector('#deal-search-box');
		const usersSearchBox = document.querySelector('#user-search-box');

		const restaurantsFilter = document.querySelector('#restaurantFilterModalButton');
		const itemsFilter = document.querySelector('#itemFilterModalButton ');
		const dealsFilter = document.querySelector('#dealFilterModalButton ');

		const restaurantsSearchBoxClose = document.querySelector('#restaurant-search-box-close');
		const itemsSearchBoxClose = document.querySelector('#item-search-box-close');
		const dealsSearchBoxClose = document.querySelector('#deal-search-box-close');
		const usersSearchBoxClose = document.querySelector('#user-search-box-close');

		const indexSearchModalRestaurants = document.querySelector('#index-search-modal-restaurants');
		const indexSearchModalItems = document.querySelector('#index-search-modal-items');
		const indexSearchModalDeals = document.querySelector('#index-search-modal-deals');
		const indexSearchModalUsers = document.querySelector('#index-search-modal-users');

    const restaurantsSearchInput = document.querySelector('#index-search-form-restaurants input[type="text"]');
    const itemsSearchInput = document.querySelector('#index-search-form-items input[type="text"]');
    const dealsSearchInput = document.querySelector('#index-search-form-deals input[type="text"]');
    const usersSearchInput = document.querySelector('#index-search-form-users input[type="text"]');

    const searchClearRestaurants = restaurantsSearchBox.querySelector('.search-clear');
    const searchClearItems = itemsSearchBox.querySelector('.search-clear');
    const searchClearDeals = dealsSearchBox.querySelector('.search-clear');
    const searchClearUsers = usersSearchBox.querySelector('.search-clear');

    function simulateBackspace(inputElement) {
      const currentValue = inputElement.value;
      if (currentValue.length > 0) {
        inputElement.value = currentValue.slice(0, -1); // Remove the last character
        inputElement.dispatchEvent(new Event('input', { bubbles: true })); // Trigger input event
        setTimeout(() => simulateBackspace(inputElement)); // Repeat with a delay
      }
    }

    function toggleSearchClearRestaurants() {
      if (restaurantsSearchInput.value.length > 0) {
        searchClearRestaurants.style.display = "block";
      } else {
        searchClearRestaurants.style.display = "none";
      }
    }

    function toggleSearchClearItems() {
      if (itemsSearchInput.value.length > 0) {
        searchClearItems.style.display = "block";
      } else {
        searchClearItems.style.display = "none";
      }
    }

    function toggleSearchClearUsers() {
      if (usersSearchInput.value.length > 0) {
        searchClearUsers.style.display = "block";
      } else {
        searchClearUsers.style.display = "none";
      }
    }

    function toggleSearchClearDeals() {
      if (dealsSearchInput.value.length > 0) {
        searchClearDeals.style.display = "block";
      } else {
        searchClearDeals.style.display = "none";
      }
    }

    toggleSearchClearRestaurants()
    toggleSearchClearItems()
    toggleSearchClearDeals()
    toggleSearchClearUsers()

    restaurantsSearchInput.addEventListener("input", toggleSearchClearRestaurants);
    itemsSearchInput.addEventListener("input", toggleSearchClearItems);
    dealsSearchInput.addEventListener("input", toggleSearchClearDeals);
    usersSearchInput.addEventListener("input", toggleSearchClearUsers);

    searchClearRestaurants.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(restaurantsSearchInput);
    });

    searchClearItems.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(itemsSearchInput);
    });

    searchClearDeals.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(dealsSearchInput);
    });

    searchClearUsers.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(usersSearchInput);
    });

		restaurantsSearchBox.addEventListener('click', () => {
			restaurantsSearchBoxClose.style.display = 'block';
			indexSearchModalRestaurants.style.display = 'block';
			restaurantsFilter.style.display = 'block';
		});

		restaurantsSearchBoxClose.addEventListener('click', () => {
			restaurantsSearchBoxClose.style.display = 'none';
			indexSearchModalRestaurants.style.display = 'none';
			restaurantsFilter.style.display = 'none';

		});

    restaurantsSearchBoxClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(restaurantsSearchInput);
    });

		itemsSearchBox.addEventListener('click', () => {
			itemsSearchBoxClose.style.display = 'block';
			indexSearchModalItems.style.display = 'block';
			itemsFilter.style.display = 'block';
		});

		itemsSearchBoxClose.addEventListener('click', () => {
			itemsSearchBoxClose.style.display = 'none';
			indexSearchModalItems.style.display = 'none';
			itemsFilter.style.display = 'none';
		});

    itemsSearchBoxClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(itemsSearchInput);
    });

		dealsSearchBox.addEventListener('click', () => {
			dealsSearchBoxClose.style.display = 'block';
			indexSearchModalDeals.style.display = 'block';
			dealsFilter.style.display = 'block';
		});

		dealsSearchBoxClose.addEventListener('click', () => {
			dealsSearchBoxClose.style.display = 'none';
			indexSearchModalDeals.style.display = 'none';
			dealsFilter.style.display = 'none';
		});

    dealsSearchBoxClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(dealsSearchInput);
    });

		usersSearchBox.addEventListener('click', () => {
			usersSearchBoxClose.style.display = 'block';
			indexSearchModalUsers.style.display = 'block';
		});

		usersSearchBoxClose.addEventListener('click', () => {
			usersSearchBoxClose.style.display = 'none';
			indexSearchModalUsers.style.display = 'none';
		});

    usersSearchBoxClose.addEventListener('click', function(e) {
      e.preventDefault();
      simulateBackspace(usersSearchInput);
    });
	}
};

export default restaurantIndexSearchModal;
