const feedFilter = () => {
	const feedFilterPresent = document.querySelector('#feedFilterButton');

	if (feedFilterPresent) {
		const feedFilterButton = document.querySelector('#feedFilterButton');
		const feedFilterModal = document.querySelector('#feedFilterModal');
    const feedFilterModalContent = document.querySelector('#feedModalContent');
		const feedFilterModalClose = document.querySelector('.feed-filter-modal-close');
		// const userPostSwitchOn = document.querySelector('.following-users-active');
		// const userPostSwitchOff = document.querySelector('.following-users-inactive');
		// const restaurantPostSwitchOn = document.querySelector('.following-restaurants-active');
		// const restaurantPostSwitchOff = document.querySelector('.following-restaurants-inactive');
		// const userPostsFeed = document.querySelector('.user-posts-feed');
		// const restaurantPostsFeed = document.querySelector('.restaurant-posts-feed');

		feedFilterButton.addEventListener('click', () => {
			feedFilterModal.classList.add('visible');
			document.body.style.overflow = 'hidden';
		});

		feedFilterModalClose.addEventListener('click', () => {
			feedFilterModal.classList.remove('visible');
			document.body.style.overflow = 'auto';
		});

    feedFilterModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!feedFilterModalContent.contains(event.target)) {
          feedFilterModal.classList.remove('visible');
          document.body.style.overflow = 'auto';
        }
    });

		// userPostSwitchOff.addEventListener('click', () => {
		// 	userPostSwitchOff.style.display = 'none';
		// 	userPostSwitchOn.style.display = 'block';
		// 	restaurantPostSwitchOff.style.display = 'block';
		// 	restaurantPostSwitchOn.style.display = 'none';
		// 	userPostsFeed.style.display = 'block';
		// 	restaurantPostsFeed.style.display = 'none';
		// });

		// restaurantPostSwitchOff.addEventListener('click', () => {
		// 	restaurantPostSwitchOff.style.display = 'none';
		// 	restaurantPostSwitchOn.style.display = 'block';
		// 	userPostSwitchOff.style.display = 'block';
		// 	userPostSwitchOn.style.display = 'none';
		// 	restaurantPostsFeed.style.display = 'block';
		// 	userPostsFeed.style.display = 'none';
		// });
	}
};

export default feedFilter;
