import { Controller } from 'stimulus';
import Rails from '@rails/ujs';
import { clickChangeActive } from '../components/activities/click_change';
import openCloseListModals from '../components/pages_home_lists/open_close_list_modals';
import addPostToList from '../components/pages_home_lists/add_post_to_list';
import listNewModal from '../components/pages_home_lists/list_new_modal';
import listCreateOrchestration from '../components/pages_home_lists/list_create_orchestration';
import shareButtonFunction from '../components/activities/share_button';
import { postPhotoModals, itemPhotoModals } from '../components/modals/restaurant_show_modals';
import signUpModals from '../components/modals/sign_in_modal';
import reportModals from '../components/modals/report_modals';
import postModalsRestaurant from '../components/modals/post_modals_restaurant';

export default class extends Controller {
  static targets = ['entries', 'pagination'];

  initialize() {
    this.loading = false;
  }

  scroll() {
    const next_page = this.paginationTarget.querySelector("a[rel='next']");

    if (next_page === null) {
      return;
    } // Return if there is no additional pages
    const url = next_page.href; // We have href if there are more pages to load

    const body = document.body,
      html = document.documentElement;

    const height = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    if (window.pageYOffset >= height - window.innerHeight - 500) {
      if (this.loading) return;

      this.loadMore(url);
    }
  }

  loadMore(url) {
    this.loading = true;

    Rails.ajax({
      type: 'GET',
      url: url,
      dataType: 'json',
      success: (data) => {
        this.entriesTarget.insertAdjacentHTML('beforeend', data.entries);
        this.paginationTarget.innerHTML = data.pagination;
        this.loading = false;
        clickChangeActive();
        openCloseListModals();
        addPostToList();
        listNewModal();
        listCreateOrchestration();
        shareButtonFunction();
        postPhotoModals();
        signUpModals();
        reportModals();
        itemPhotoModals();
        postModalsRestaurant();
      },
    });
  }
}
