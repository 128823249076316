import removeAllEventListeners from './functions/remove_all_event_listeners';
import {
  listModalPlusButtonsActions,
  listModalNewListModalBackArrowButtonsActions,
} from './functions/list_new_modal_functions';

const listNewModal = () => {
  const listModalPlusButtonsAllWithListenersLength = document.querySelectorAll('img.list-form-show-btn').length;

  // Guard clause
  if (listModalPlusButtonsAllWithListenersLength === 0) return;

  const listModalPlusButtonsAllWithListeners = document.querySelectorAll('img.list-form-show-btn');
  const listModalNewListModalBackArrowButtonsAllWithListeners = document.querySelectorAll('img.list-form-show-hide');

  removeAllEventListeners(listModalPlusButtonsAllWithListeners);
  removeAllEventListeners(listModalNewListModalBackArrowButtonsAllWithListeners);

  const listModalPlusButtonsAll = document.querySelectorAll('img.list-form-show-btn');
  const listModalNewListModalBackArrowButtonsAll = document.querySelectorAll('img.list-form-show-hide');

  listModalPlusButtonsActions(listModalPlusButtonsAll);
  listModalNewListModalBackArrowButtonsActions(listModalNewListModalBackArrowButtonsAll);
};

export default listNewModal;
