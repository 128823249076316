const dashboardModals = () => {
	const dashboardNavbarModal = document.querySelector('#dashboardModal');
	const dashboardAccessPage = document.querySelector('.dashboardAccess');

	if (dashboardAccessPage) {
		const dashboardButton = document.querySelector('#dashboardModalButton');
    const dashboardModalContent = document.querySelector('.modal-content-dashboard');
		const dashboardCloseButton = document.querySelector('.dashboard-modal-close').querySelector('img');

		dashboardButton.addEventListener('click', function () {
			dashboardNavbarModal.style.display = 'block';
			document.body.style.overflow = 'hidden';
		});

		dashboardCloseButton.addEventListener('click', function () {
			dashboardNavbarModal.style.display = 'none';
			document.body.style.overflow = 'auto';
		});

    dashboardNavbarModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!dashboardModalContent.contains(event.target)) {
          dashboardNavbarModal.style.display = 'none';
          document.body.style.overflow = 'auto';
        }
    });
	}
};

export default dashboardModals;
