import 'jquery-bar-rating';

const initStarRating = () => {
  const orderType = document.querySelector('.orderType');
  // TODO
  $('#review_rating').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      orderType.style.display = 'block';
      const form = $('form.review_form');
    },
  });
  $('#review_portion_size').barrating({
    // theme: 'bars-movie',
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#review_waiting_time').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#review_service').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#restaurant_review_rating').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#restaurant_review_food').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#restaurant_review_drinks').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#restaurant_review_ambience').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
  $('#restaurant_review_service').barrating({
    theme: 'css-stars',
    onSelect: (value, text, event) => {
      const form = $('form.review_form');
    },
  });
};

export default initStarRating;
