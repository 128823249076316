const homeFollowUnfollow = () => {
  const suggestedUsersDesktop = document.querySelector('.suggestedUsersDesktop');

  if (suggestedUsersDesktop) {
    const suggestedUsersAvailable = document.querySelector('.suggested-users-desktop-home-feed-title');

    if (suggestedUsersAvailable) {
      const hideFollow = document.querySelectorAll(".userFollowHomePage")
      const hideFollowing = document.querySelectorAll(".userFollowingHomePage")

      hideFollow.forEach((button) => {
        const showFollowing = button.parentNode.querySelector('.userFollowingHomePage');

        button.addEventListener('click', function () {
          button.style.display = 'none';
          showFollowing.style.display = 'block';
        });
      });

      hideFollowing.forEach((button) => {
        const showFollow = button.parentNode.querySelector('.userFollowHomePage');

        button.addEventListener('click', function () {
          button.style.display = 'none';
          showFollow.style.display = 'block';
        });
      });
    }
  }
};

export default homeFollowUnfollow;
