// Issues:
const addPostToList = () => {
  const bookmarkItemPage = document.querySelector('.bookmark-item-page');
  const successListAddFlash = document.querySelector('.success-list-add-flash');
  const successListRemoveFlash = document.querySelector('.success-list-remove-flash');
  const allTagBoxes = document.querySelectorAll('.list-add-button-and-modal:not(.listener-added)');

  if (bookmarkItemPage) {
    allTagBoxes.forEach((tagBox, index) => {
      tagBox.classList.add('listener-added');

      const shownBookmark = tagBox.querySelector('.bookmark-show');
      const hiddenBookmark = tagBox.querySelector('.bookmark-hide');
      const listBoxes = tagBox.querySelectorAll('.list-box');

      listBoxes.forEach((listBox) => {
        listBox.addEventListener('click', () => {
          shownBookmark.classList.add('hidden');
          hiddenBookmark.classList.remove('hidden');

          const belongToListDiv = listBox.querySelector('.item-belongs-to-list');
          if (belongToListDiv) {
            successListRemoveFlash.classList.add('success-flash-pop');
            setTimeout(fadeRemoveSuccessFlashTimer, 4000);
          } else {
            successListAddFlash.classList.add('success-flash-pop');
            setTimeout(fadeAddSuccessFlashTimer, 4000);
          }

          const modalAddToList = listBox.parentNode.parentNode.parentNode.parentNode;
          modalAddToList.classList.add('hidden');

          document.body.style.overflow = 'auto';
        });
      });
    });

    const fadeAddSuccessFlashTimer = () => {
      successListAddFlash.classList.remove('success-flash-pop');
    };

    const fadeRemoveSuccessFlashTimer = () => {
      successListRemoveFlash.classList.remove('success-flash-pop');
    };
  }
};

export default addPostToList;
