const fadeAlertModal = () => {
	const alertModal = document.querySelector('.alertFade');

	if (alertModal) {
		const myTimeout = setTimeout(fadeAlertModalTimer, 3000);
	}
};

const fadeAlertModalTimer = () => {
	const alertModal = document.querySelector('.alertFade');

	if (alertModal) {
		alertModal.style.display = 'none';
	}
};

export default fadeAlertModal;
