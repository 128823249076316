//
// Function to switch between search pages on restaurant index / discover page
//

const switchTabs = () => {
	const tabsContainer = document.querySelector('#discover-navigation-tabs');

	if (tabsContainer) {
		const restaurantsTab = tabsContainer.querySelector('#restaurant-index-restaurants-tab');
		const itemsTab = tabsContainer.querySelector('#restaurant-index-items-tab');
		const dealsTab = tabsContainer.querySelector('#restaurant-index-deals-tab');
		const usersTab = tabsContainer.querySelector('#restaurant-index-users-tab');

		const restaurantsContent = document.querySelector('#discover-restaurants-tab-content');
		const itemsContent = document.querySelector('#discover-items-tab-content');
		const dealsContent = document.querySelector('#discover-deals-tab-content');
		const usersContent = document.querySelector('#discover-users-tab-content');

		restaurantsTab.addEventListener('click', () => {
			restaurantsTab.classList.add('navigation-tabs-active');
			restaurantsTab.classList.remove('navigation-tabs-inactive');
			itemsTab.classList.remove('navigation-tabs-active');
			itemsTab.classList.add('navigation-tabs-inactive');
			dealsTab.classList.remove('navigation-tabs-active');
			dealsTab.classList.add('navigation-tabs-inactive');
			usersTab.classList.remove('navigation-tabs-active');
			usersTab.classList.add('navigation-tabs-inactive');

			restaurantsContent.style.display = 'block';
			itemsContent.style.display = 'none';
			dealsContent.style.display = 'none';
			usersContent.style.display = 'none';
		});

		itemsTab.addEventListener('click', () => {
			restaurantsTab.classList.remove('navigation-tabs-active');
			restaurantsTab.classList.add('navigation-tabs-inactive');
			itemsTab.classList.add('navigation-tabs-active');
			itemsTab.classList.remove('navigation-tabs-inactive');
			dealsTab.classList.remove('navigation-tabs-active');
			dealsTab.classList.add('navigation-tabs-inactive');
			usersTab.classList.remove('navigation-tabs-active');
			usersTab.classList.add('navigation-tabs-inactive');

			restaurantsContent.style.display = 'none';
			itemsContent.style.display = 'block';
			dealsContent.style.display = 'none';
			usersContent.style.display = 'none';
		});

		dealsTab.addEventListener('click', () => {
			restaurantsTab.classList.remove('navigation-tabs-active');
			restaurantsTab.classList.add('navigation-tabs-inactive');
			itemsTab.classList.remove('navigation-tabs-active');
			itemsTab.classList.add('navigation-tabs-inactive');
			dealsTab.classList.add('navigation-tabs-active');
			dealsTab.classList.remove('navigation-tabs-inactive');
			usersTab.classList.remove('navigation-tabs-active');
			usersTab.classList.add('navigation-tabs-inactive');

			restaurantsContent.style.display = 'none';
			itemsContent.style.display = 'none';
			dealsContent.style.display = 'block';
			usersContent.style.display = 'none';
		});

		usersTab.addEventListener('click', () => {
			restaurantsTab.classList.remove('navigation-tabs-active');
			restaurantsTab.classList.add('navigation-tabs-inactive');
			itemsTab.classList.remove('navigation-tabs-active');
			itemsTab.classList.add('navigation-tabs-inactive');
			dealsTab.classList.remove('navigation-tabs-active');
			dealsTab.classList.add('navigation-tabs-inactive');
			usersTab.classList.add('navigation-tabs-active');
			usersTab.classList.remove('navigation-tabs-inactive');

			restaurantsContent.style.display = 'none';
			itemsContent.style.display = 'none';
			dealsContent.style.display = 'none';
			usersContent.style.display = 'block';
		});
	}
};

export default switchTabs;
