const restaurantShowFilterModal = () => {
	const restaurantShowPage = document.querySelector('.restaurantShowPageMenu');

	if (restaurantShowPage) {
		const restaurantShowFilterButton = document.querySelector('#restaurantShowFilterButton');
		const restaurantShowFilterModal = document.querySelector('#restaurantShowFilterModal');
    const restaurantShowFilterModalContent = document.querySelector('.restaurantShowFilterModalContent');
		const restaurantShowFilterModalClose = document.querySelector('.restaurant-show-filter-modal-close');

		restaurantShowFilterButton.addEventListener('click', () => {
			restaurantShowFilterModal.classList.add('visible');
			document.body.style.overflow = 'hidden';
		});

		restaurantShowFilterModalClose.addEventListener('click', () => {
			restaurantShowFilterModal.classList.remove('visible');
			document.body.style.overflow = 'auto';
		});

    restaurantShowFilterModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!restaurantShowFilterModalContent.contains(event.target)) {
          restaurantShowFilterModal.classList.remove('visible');
          document.body.style.overflow = 'auto';
        }
    });
	}
};

export default restaurantShowFilterModal;
