const reportModals = () => {
  const postShowPage = document.querySelector(".postShow");
  const profileShowPage = document.querySelector("#profile");
  const restaurantShowPage = document.querySelector(".restaurantShowPage")
  const itemShowPage = document.querySelector(".itemShowPage")
  const followPage = document.querySelector(".followPage")
  const blockPage = document.querySelector(".blockPage")
  const unblockPage = document.querySelector(".unblockPage")
  const listShowPage = document.querySelector(".listShowPage")
  const menuItemShow = document.querySelector(".menuItemShow")
  // const postModal = document.querySelector(".postModal");

  if (restaurantShowPage) {
    const restaurantReportButton = document.querySelectorAll(".restaurantReportIcon");
    const restaurantReportCloseButton = document.querySelectorAll(".closeRestaurantReportModal");
    const closeReportModal = document.querySelectorAll(".closeRestaurantReport")
    const restaurantReportModal = document.querySelector(".restaurantReportModal")
    const restaurantReportModalContent = document.querySelector("#restaurantReportModalContent")
    const successReportFlash = document.querySelector('.successRestaurantReportFlash');

    const qrCodeModalButton = document.querySelector('.qrCodeModalButton');
    const qrCodeModal = document.querySelector('.qrCodeModal');
    const qrCodeModalClose = document.querySelector('.qrCodeModalClose');
    const qrCodeModalContent = document.querySelector('.qr-code-popup');

    restaurantReportButton.forEach((button) => {
        button.addEventListener('click', function() {
        restaurantReportModal.style.display = "block";
      });
    });

    restaurantReportModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!restaurantReportModalContent.contains(event.target)) {
          restaurantReportModal.style.display = "none";
        }
    });

    restaurantReportCloseButton.forEach((button) => {
      button.addEventListener('click', function() {
      restaurantReportModal.style.display = "none";
      });
    });

    closeReportModal.forEach((button) => {
      button.addEventListener('click', function() {
      restaurantReportModal.style.display = "none";
      successReportFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
      });
    });

    const fadeAddSuccessFlashTimer = () => {
      successReportFlash.classList.remove('success-flash-pop');
    };

    qrCodeModalButton.addEventListener('click', function() {
      qrCodeModal.style.display = "block";
    });

    qrCodeModalClose.addEventListener('click', function() {
      qrCodeModal.style.display = "none";
    });

    qrCodeModal.addEventListener('click', function(event) {
        // Check if the clicked element is the excluded div or a descendant of it
        if (!qrCodeModalContent.contains(event.target)) {
          qrCodeModal.style.display = "none";
        }
    });

    const itemReportButton = document.querySelectorAll(".itemReportIcon");
    const itemReportCloseButton = document.querySelectorAll(".closeItemReportModal");
    const closeItemReportModal = document.querySelectorAll(".closeItemReport")

    itemReportButton.forEach((button) => {
      const itemReportModal = button.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "block";
      });
    });

    itemReportCloseButton.forEach((button) => {
      const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "none";
      });
    });

    closeItemReportModal.forEach((button) => {
      const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "none";
      successReportFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
      });
    });

  }


  // if (menuItemShow) {
  //   const itemReportButton = document.querySelectorAll(".itemReportIcon");
  //   const itemReportCloseButton = document.querySelectorAll(".closeItemReportModal");
  //   const closeItemReportModal = document.querySelectorAll(".closeItemReport")

  //   itemReportButton.forEach((button) => {
  //     const itemReportModal = button.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

  //     button.addEventListener('click', function() {
  //     itemReportModal.style.display = "block";
  //     });
  //   });

  //   itemReportCloseButton.forEach((button) => {
  //     const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

  //     button.addEventListener('click', function() {
  //     itemReportModal.style.display = "none";
  //     });
  //   });

  //   closeItemReportModal.forEach((button) => {
  //     const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");

  //     button.addEventListener('click', function() {
  //     itemReportModal.style.display = "none";
  //     successReportFlash.classList.add('success-flash-pop');
  //     setTimeout(fadeAddSuccessFlashTimer, 4000);
  //     });
  //   });
  // }

  if (itemShowPage) {
    const itemReportButton = document.querySelectorAll(".itemReportIcon");
    const itemReportCloseButton = document.querySelectorAll(".closeItemReportModal");
    const closeItemReportModal = document.querySelectorAll(".closeItemReport")
    const successReportFlash = document.querySelector('.success-item-report-flash');

    itemReportButton.forEach((button) => {
      // const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");
      const itemReportModal = document.querySelector(".itemReportModal");
      const itemReportModalContent = document.querySelector("#itemReportModalContent");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "block";
      });

      itemReportModal.addEventListener('click', function(event) {
          // Check if the clicked element is the excluded div or a descendant of it
          if (!itemReportModalContent.contains(event.target)) {
            itemReportModal.style.display = "none";
          }
      });
    });

    itemReportCloseButton.forEach((button) => {
      // const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");
      const itemReportModal = document.querySelector(".itemReportModal");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "none";
      });
    });

    closeItemReportModal.forEach((button) => {
      // const itemReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".itemReportModal");
      const itemReportModal = document.querySelector(".itemReportModal");

      button.addEventListener('click', function() {
      itemReportModal.style.display = "none";
      successReportFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
      });
    });

    const fadeAddSuccessFlashTimer = () => {
      successReportFlash.classList.remove('success-flash-pop');
    };
  }

  if (postShowPage) {
    const postReportButton = document.querySelectorAll(".postReportIcon");
    const postReportCloseButton = document.querySelectorAll(".closePostReport");
    const closeReportModal = document.querySelectorAll(".closeReportModal")
    const successReportFlash = document.querySelector('.success-review-report-flash');
    const postReportModalContent = document.querySelector('#reportModalContent');

    postReportButton.forEach((button) => {
      // const postReportModal = button.parentNode.parentNode.parentNode.querySelector(".postReportModal");
      const postReportModal = document.querySelector(".postReportModal");

      button.addEventListener('click', function() {
      postReportModal.style.display = "block";
      });

      postReportModal.addEventListener('click', function(event) {
          // Check if the clicked element is the excluded div or a descendant of it
          if (!postReportModalContent.contains(event.target)) {
            postReportModal.style.display = "none";
          }
      });
    });

    postReportCloseButton.forEach((button) => {
      // const postReportModal = button.parentNode.parentNode.parentNode.parentNode.querySelector(".postReportModal");
      const postReportModal = document.querySelector(".postReportModal");

      button.addEventListener('click', function() {
      postReportModal.style.display = "none";
      });
    });

    closeReportModal.forEach((button) => {
      // const postReportModal = button.parentNode.parentNode.parentNode.parentNode.parentNode.querySelector(".postReportModal");
      const postReportModal = document.querySelector(".postReportModal");

      button.addEventListener('click', function() {
      postReportModal.style.display = "none";
      successReportFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
      });
    });

    const fadeAddSuccessFlashTimer = () => {
      successReportFlash.classList.remove('success-flash-pop');
    };

  }

  if (profileShowPage) {
    const userReportButton = document.querySelectorAll(".userReportIcon");
    const userReportCloseButton = document.querySelectorAll(".closeUserReportModal");
    const closeReportModal = document.querySelectorAll(".closeUserReport")
    const closeReportModalBlock = document.querySelectorAll(".blockButton")
    const userReportModal = document.querySelector(".userReportModal")
    const successReportFlash = document.querySelector('.successUserReportFlash');
    const successReportFlashBlock = document.querySelector('.successUserBlockFlash');
    const userReportModalContent = document.querySelectorAll('#userReportModalContent');

    userReportButton.forEach((button) => {
      button.addEventListener('click', function() {
        userReportModal.style.display = "block";
      });
    });

    userReportModal.addEventListener('click', function(event) {
        let isContained = false;

        // Check each element in the NodeList
        userReportModalContent.forEach(function(content) {
            if (content.contains(event.target)) {
                isContained = true;
            }
        });

        // Hide the modal if the clicked element is not contained in any of the modal contents
        if (!isContained) {
            userReportModal.style.display = "none";
        }
    });

    userReportCloseButton.forEach((button) => {
      button.addEventListener('click', function() {
      userReportModal.style.display = "none";
      });
    });

    closeReportModal.forEach((button) => {
      button.addEventListener('click', function() {
      userReportModal.style.display = "none";
      successReportFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
      });
    });

    const fadeAddSuccessFlashTimer = () => {
      successReportFlash.classList.remove('success-flash-pop');
    };

    closeReportModalBlock.forEach((button) => {
      button.addEventListener('click', function() {
      userReportModal.style.display = "none";
      successReportFlashBlock.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimerBlock, 4000);
      });
    });

    const fadeAddSuccessFlashTimerBlock = () => {
      successReportFlashBlock.classList.remove('success-flash-pop');
    };

  }

  if (followPage) {
    const followButton = document.querySelectorAll('.followButton');
    const unfollowButton = document.querySelectorAll('.unfollowButton');
    const unfollowButtonOpen = document.querySelectorAll('.unfollowButtonOpen');
    const unfollowModal = document.querySelector('.unfollowModal');
    const unfollowCloseModal = document.querySelectorAll('.unfollowCloseModal');
    const successFollowFlash = document.querySelector('.successFollow');
    const successUnfollowFlash = document.querySelector('.successUnfollow');
    const userReportModalContent = document.querySelectorAll('#userReportModalContent');

    followButton.forEach((button) => {
      button.addEventListener('click', function() {
      successFollowFlash.classList.add('success-flash-pop');
      setTimeout(fadeSuccessFollowFlash, 4000);
      });
    });

    unfollowButtonOpen.forEach((button) => {
      button.addEventListener('click', function() {
        unfollowModal.style.display = "block";
      });
    });

    unfollowButton.forEach((button) => {
      button.addEventListener('click', function() {
      unfollowModal.style.display = "none";
      successUnfollowFlash.classList.add('success-flash-pop');
      setTimeout(fadeSuccessUnfollowFlash, 4000);
      });
    });

    unfollowCloseModal.forEach((button) => {
      button.addEventListener('click', function() {
      unfollowModal.style.display = "none";
      });

      unfollowModal.addEventListener('click', function(event) {
          let isContained = false;

          // Check each element in the NodeList
          userReportModalContent.forEach(function(content) {
              if (content.contains(event.target)) {
                  isContained = true;
              }
          });

          // Hide the modal if the clicked element is not contained in any of the modal contents
          if (!isContained) {
              unfollowModal.style.display = "none";
          }
      });
    });

    const fadeSuccessFollowFlash = () => {
      successFollowFlash.classList.remove('success-flash-pop');
    };

    const fadeSuccessUnfollowFlash = () => {
      successUnfollowFlash.classList.remove('success-flash-pop');
    };
  }

  if (blockPage) {
    const blockButton = document.querySelector('.blockButton');
    const unblockButton = document.querySelectorAll('.unblockButton');
    const unblockButtonOpen = document.querySelectorAll('.unblockButtonOpen');
    const unblockModal = document.querySelector('.unblockModal');
    const unblockCloseModal = document.querySelector('.unblockCloseModal');
    const successUnblockFlash = document.querySelector('.successUserUnblockFlash');
    const followButtons = document.querySelectorAll('.followButtons');
    const userReportModal = document.querySelector(".userReportModal");

    blockButton.addEventListener('click', function() {
      blockButton.style.display = "none";
      followButtons.forEach((button) => {
        button.style.display = "none";
      });
      unblockButtonOpen.forEach((button) => {
        button.style.display = "block";
      });
      unblockButton.forEach((button) => {
        button.style.display = "block";
      });
    });

    unblockButtonOpen.forEach((button) => {
        button.addEventListener('click', function() {
        unblockModal.style.display = "block";
      });
    });

    unblockButton.forEach((button) => {
      button.addEventListener('click', function() {
      unblockModal.style.display = "none";
      unblockButtonOpen.forEach((button) => {
        button.style.display = "none";
      });
      userReportModal.style.display = "none";
      followButtons.forEach((button) => {
        button.style.display = "block";
      });
      blockButton.style.display = "block";
      successUnblockFlash.classList.add('success-flash-pop');
      setTimeout(fadeSuccessUnblockFlash, 4000);
      unblockButton.forEach((button) => {
        button.style.display = "none";
        });
      });
    });

    unblockCloseModal.addEventListener('click', function() {
    unblockModal.style.display = "none";
    });

    const fadeSuccessUnblockFlash = () => {
      successUnblockFlash.classList.remove('success-flash-pop');
    };

  }

  if (unblockPage) {
    const unblockButton = document.querySelectorAll('.unblockButton');
    const unblockButtonOpen = document.querySelectorAll('.unblockButtonOpen');
    const unblockModal = document.querySelector('.unblockModal');
    const unblockCloseModal = document.querySelector('.unblockCloseModal');
    const successUnblockFlash = document.querySelector('.successUserUnblockFlash');
    const followButtons = document.querySelectorAll('.followButtons');
    // const userReportModal = document.querySelector(".userReportModal");

    unblockButtonOpen.forEach((button) => {
        button.addEventListener('click', function() {
        unblockModal.style.display = "block";
      });
    });

    unblockButton.forEach((button) => {

      button.addEventListener('click', function() {

        unblockModal.style.display = "none";

        unblockButtonOpen.forEach((button) => {
          button.style.display = "none";
        });

        // userReportModal.style.display = "none";

        followButtons.forEach((button) => {
          button.style.display = "block";
        });

        successUnblockFlash.classList.add('success-flash-pop');
        setTimeout(fadeSuccessUnblockFlash, 4000);
        unblockButton.forEach((button) => {
          button.style.display = "none";
          });
      });

    });

    unblockCloseModal.addEventListener('click', function() {
      unblockModal.style.display = "none";
    });

    const fadeSuccessUnblockFlash = () => {
      successUnblockFlash.classList.remove('success-flash-pop');
    };
  }

};

export default reportModals;
