const checkboxInputsGenerator = (input) => {
	let url = ``;
	let count = 0;
	const checkboxes = document.querySelector(`#collapse-${input}`).querySelectorAll("input[type='checkbox']");
	checkboxes.forEach((checkbox) => {
		if (checkbox.checked) {
			url += `&${input}%5B%5D=${checkbox.value}`;
			count += 1;
		}
	});
	return { url, count };
};

export default checkboxInputsGenerator;
