import restaurantShowControllerInputs from './restaurant_show_controller_inputs';
import searchQueryGenerator from '../../shared/search_query_generator';
import fetchData from '../../shared/fetch_data';
import createItemsNewPostRestaurantShowHTML from './create_items_new_post_rs_html';

// Clear HTML Fucntions
const clearPageHtml = () => {
	const itemsContainer = document.querySelector('#restaurant-show-items-post-container');
	itemsContainer.innerHTML = '';
};

const insertItemsHTML = (html) => {
	const itemsContainer = document.querySelector('#restaurant-show-items-post-container');
	itemsContainer.innerHTML = html;
};

const restaurantItemsSearchOrchestration = async (query) => {
	const { baseUrl, restaurantHandle } = restaurantShowControllerInputs();
	const queryUrl = searchQueryGenerator('item', query);

	const url = `${baseUrl}/${restaurantHandle}?${queryUrl}`;

	if (query.length === 0) {
		clearPageHtml();
	} else {
		const data = await fetchData(url);
		const html = createItemsNewPostRestaurantShowHTML(data);
		insertItemsHTML(html);
	}
};

export default restaurantItemsSearchOrchestration;
