const copyText = () => {
	const restaurantPassElem = document.querySelector('#restaurant-password');
	const copyButton = document.querySelector('#copy-password-button');

  const restaurantShowPage = document.querySelector('.restaurantShowPage');
  const restaurantUrlElem = document.querySelector('#restaurant-url');
  const urlCopyButton = document.querySelector('#copy-url-button');
  const successUrlFlash = document.querySelector('.successRestaurantURLFlash');


	if (restaurantPassElem) {
		const copyText = restaurantPassElem.innerText;

		copyButton.addEventListener('click', () => {
			navigator.clipboard.writeText(copyText);
			copyButton.innerText = 'Copied';
		});
	}

  if (restaurantShowPage) {
    const copyUrlText = restaurantUrlElem.href;

    urlCopyButton.addEventListener('click', () => {
      navigator.clipboard.writeText(copyUrlText);
      successUrlFlash.classList.add('success-flash-pop');
      setTimeout(fadeAddSuccessFlashTimer, 4000);
    });

    const fadeAddSuccessFlashTimer = () => {
      successUrlFlash.classList.remove('success-flash-pop');
    };
  }

};

export default copyText;
