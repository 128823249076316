const viewedReport = () => {
  const reportDashboardPage = document.querySelector('.reportDashboard');
  const changeReportStatus = document.querySelectorAll('.change-report-status');

  if (reportDashboardPage) {
    changeReportStatus.forEach((button) => {
      button.addEventListener('click', () => {
        const statusNew = button.parentNode.parentNode.querySelector('.show-new-status');
        const statusViewed = button.parentNode.parentNode.querySelector('.hidden-viewed-status');

        statusViewed.style.display = 'block';
        statusNew.style.display = 'none';
        button.style.display = 'none';
      });
    });
  }
}

export default viewedReport;
