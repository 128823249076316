const userDashboardClickChange = () => {
  const userDashboard = document.querySelector('#user-dashboard');

  if (userDashboard) {

    const changeLocationBoxNarrow = document.querySelector('.location-icon-user-dashboard-narrow');
    const changeLocationIconNarrow = document.querySelector('.changeLocationIconNarrow');
    const changeLocationIconHiddenNarrow = document.querySelector('.changeLocationIconHiddenNarrow');

    const changeTagsBoxNarrow = document.querySelector('.tags-icon-user-dashboard-narrow');
    const changeTagsIconNarrow = document.querySelector('.changeTagsIconNarrow');
    const changeTagsIconHiddenNarrow = document.querySelector('.changeTagsIconHiddenNarrow');

    const changePointsBoxNarrow = document.querySelector('.points-icon-user-dashboard-narrow');
    const changePointsIconNarrow = document.querySelector('.changePointsIconNarrow');
    const changePointsIconHiddenNarrow = document.querySelector('.changePointsIconHiddenNarrow');

    const changeMoreBoxNarrow = document.querySelector('.more-icon-user-dashboard-narrow');
    const changeMoreIconNarrow = document.querySelector('.changeMoreIconNarrow');
    const changeMoreIconHiddenNarrow = document.querySelector('.changeMoreIconHiddenNarrow');

    const changeLocationModal = document.querySelector('.user-dashboard-change-location-modal');
    const tagsModal = document.querySelector('.user-dashboard-tags-modal');
    const pointsModal = document.querySelector('.user-dashboard-points-modal');
    const moreModal = document.querySelector('.user-dashboard-more-modal');

    const userDashboardWide = document.querySelector('.user-dashboard-wide');
    const userDashboardNarrow = document.querySelector('.user-dashboard-narrow');

    const changeLocationBox = document.querySelector('.location-icon-user-dashboard');
    const changeTagsBox = document.querySelector('.tags-icon-user-dashboard');
    const changePointsBox = document.querySelector('.points-icon-user-dashboard');
    const changeMoreBox = document.querySelector('.more-icon-user-dashboard');

    const userDashboardActiveIcon = document.querySelector('.user-dashboard-active');
    const userDashboardInactiveIcon = document.querySelector('.user-dashboard-inactive');

    changeLocationBox.addEventListener('click', function () {
      // Check if changeLocationIcon is currently displayed
      userDashboardWide.style.display = 'none';
      userDashboardNarrow.classList.add('display-on-large');
      changeLocationModal.style.display = 'block';

      changeLocationIconNarrow.style.display = 'none';
      changeLocationIconHiddenNarrow.style.display = 'block';

      userDashboardActiveIcon.style.display = 'none';
      userDashboardInactiveIcon.style.display = 'block';
    });

    changeTagsBox.addEventListener('click', function () {
      // Check if changeLocationIcon is currently displayed
      userDashboardWide.style.display = 'none';
      userDashboardNarrow.classList.add('display-on-large');
      tagsModal.style.display = 'block';

      changeTagsIconNarrow.style.display = 'none';
      changeTagsIconHiddenNarrow.style.display = 'block';

      userDashboardActiveIcon.style.display = 'none';
      userDashboardInactiveIcon.style.display = 'block';
    });

    changePointsBox.addEventListener('click', function () {
      // Check if changeLocationIcon is currently displayed
      userDashboardWide.style.display = 'none';
      userDashboardNarrow.classList.add('display-on-large');
      pointsModal.style.display = 'block';

      changePointsIconNarrow.style.display = 'none';
      changePointsIconHiddenNarrow.style.display = 'block';

      userDashboardActiveIcon.style.display = 'none';
      userDashboardInactiveIcon.style.display = 'block';
    });

    changeMoreBox.addEventListener('click', function () {
      // Check if changeLocationIcon is currently displayed
      userDashboardWide.style.display = 'none';
      userDashboardNarrow.classList.add('display-on-large');
      moreModal.style.display = 'block';

      changeMoreIconNarrow.style.display = 'none';
      changeMoreIconHiddenNarrow.style.display = 'block';

      userDashboardActiveIcon.style.display = 'none';
      userDashboardInactiveIcon.style.display = 'block';
    });

    changeLocationBoxNarrow.addEventListener('click', function () {
      // Check if changeLocationIcon is currently displayed
      if (changeLocationIconNarrow.style.display !== 'none') {
        changeLocationIconNarrow.style.display = 'none';
        changeLocationIconHiddenNarrow.style.display = 'block';

        userDashboardNarrow.classList.add('display-on-large');
        userDashboardWide.style.display = 'none';

        changeLocationModal.style.display = 'block';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';

      } else {
        changeLocationIconHiddenNarrow.style.display = 'none';
        changeLocationIconNarrow.style.display = 'block';

        userDashboardWide.style.display = 'block';
        userDashboardNarrow.classList.remove('display-on-large');

        changeLocationModal.style.display = 'none';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';
      }
    });

    changeTagsBoxNarrow.addEventListener('click', function () {
      // Check if changeTagsIcon is currently displayed
      if (changeTagsIconNarrow.style.display !== 'none') {
        changeTagsIconNarrow.style.display = 'none';
        changeTagsIconHiddenNarrow.style.display = 'block';

        userDashboardNarrow.classList.add('display-on-large');
        userDashboardWide.style.display = 'none';

        tagsModal.style.display = 'block';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';

      } else {
        changeTagsIconHiddenNarrow.style.display = 'none';
        changeTagsIconNarrow.style.display = 'block';

        userDashboardWide.style.display = 'block';
        userDashboardNarrow.classList.remove('display-on-large');

        tagsModal.style.display = 'none';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';
      }
    });

    changePointsBoxNarrow.addEventListener('click', function () {
      // Check if changePointsIcon is currently displayed
      if (changePointsIconNarrow.style.display !== 'none') {
        changePointsIconNarrow.style.display = 'none';
        changePointsIconHiddenNarrow.style.display = 'block';

        userDashboardNarrow.classList.add('display-on-large');
        userDashboardWide.style.display = 'none';

        pointsModal.style.display = 'block';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';

      } else {
        changePointsIconHiddenNarrow.style.display = 'none';
        changePointsIconNarrow.style.display = 'block';

        userDashboardWide.style.display = 'block';
        userDashboardNarrow.classList.remove('display-on-large');

        pointsModal.style.display = 'none';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';
      }
    });

    changeMoreBoxNarrow.addEventListener('click', function () {
      // Check if changeMoreIcon is currently displayed
      if (changeMoreIconNarrow.style.display !== 'none') {
        changeMoreIconNarrow.style.display = 'none';
        changeMoreIconHiddenNarrow.style.display = 'block';

        userDashboardNarrow.classList.add('display-on-large');
        userDashboardWide.style.display = 'none';

        moreModal.style.display = 'block';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';

      } else {
        changeMoreIconHiddenNarrow.style.display = 'none';
        changeMoreIconNarrow.style.display = 'block';

        userDashboardWide.style.display = 'block';
        userDashboardNarrow.classList.remove('display-on-large');

        moreModal.style.display = 'none';

        userDashboardActiveIcon.style.display = 'none';
        userDashboardInactiveIcon.style.display = 'block';
      }
    });

    document.addEventListener('click', function(event) {
      // Check if the click is outside of changeLocationModal and changeLocationIconNarrow
      if (changeLocationModal.style.display === 'block' && !changeLocationModal.contains(event.target) && !changeLocationBoxNarrow.contains(event.target) && !changeLocationBox.contains(event.target)) {
        // If the click is outside both, hide changeLocationModal
        changeLocationModal.style.display = 'none';
        changeLocationIconHiddenNarrow.style.display = 'none';
        changeLocationIconNarrow.style.display = 'block';

        if (!changeTagsBoxNarrow.contains(event.target) && !changePointsBoxNarrow.contains(event.target) && !changeMoreBoxNarrow.contains(event.target)) {
          userDashboardWide.style.display = 'block';
          userDashboardNarrow.classList.remove('display-on-large');

          userDashboardActiveIcon.style.display = 'block';
          userDashboardInactiveIcon.style.display = 'none';
        }

        // userDashboardNarrow.classList.add('hide-on-large');
      }

      // Check if the click is outside of tagsModal and changeLocationIconNarrow
      if (tagsModal.style.display === 'block' && !tagsModal.contains(event.target) && !changeTagsBoxNarrow.contains(event.target) && !changeTagsBox.contains(event.target)) {
        // If the click is outside both, hide tagsModal
        tagsModal.style.display = 'none';
        changeTagsIconHiddenNarrow.style.display = 'none';
        changeTagsIconNarrow.style.display = 'block';


        if (!changeLocationBoxNarrow.contains(event.target) && !changePointsBoxNarrow.contains(event.target) && !changeMoreBoxNarrow.contains(event.target)) {
          userDashboardWide.style.display = 'block';
          userDashboardNarrow.classList.remove('display-on-large');

          userDashboardActiveIcon.style.display = 'block';
          userDashboardInactiveIcon.style.display = 'none';
        }
        // userDashboardNarrow.classList.add('hide-on-large');
      }

      // Check if the click is outside of tagsModal and changeLocationIconNarrow
      if (pointsModal.style.display === 'block' && !pointsModal.contains(event.target) && !changePointsBoxNarrow.contains(event.target) && !changePointsBox.contains(event.target)) {
        // If the click is outside both, hide tagsModal
        pointsModal.style.display = 'none';
        changePointsIconHiddenNarrow.style.display = 'none';
        changePointsIconNarrow.style.display = 'block';

        if (!changeTagsBoxNarrow.contains(event.target) && !changeLocationBoxNarrow.contains(event.target) && !changeMoreBoxNarrow.contains(event.target)) {
          userDashboardWide.style.display = 'block';
          userDashboardNarrow.classList.remove('display-on-large');

          userDashboardActiveIcon.style.display = 'block';
          userDashboardInactiveIcon.style.display = 'none';
        }
      }

      // Check if the click is outside of tagsModal and changeLocationIconNarrow
      if (moreModal.style.display === 'block' && !moreModal.contains(event.target) && !changeMoreBoxNarrow.contains(event.target) && !changeMoreBox.contains(event.target)) {
        // If the click is outside both, hide tagsModal
        moreModal.style.display = 'none';
        changeMoreIconHiddenNarrow.style.display = 'none';
        changeMoreIconNarrow.style.display = 'block';

        if (!changeTagsBoxNarrow.contains(event.target) && !changePointsBoxNarrow.contains(event.target) && !changeLocationBoxNarrow.contains(event.target)) {
          userDashboardWide.style.display = 'block';
          userDashboardNarrow.classList.remove('display-on-large');

          userDashboardActiveIcon.style.display = 'block';
          userDashboardInactiveIcon.style.display = 'none';
        }
      }
    });

  }
};

export default userDashboardClickChange;
