import { enableSubmitButton, disableSubmitButton } from './enable_disable_submit_button';

const updatePage = (status) => {
	const $handleStatus = document.querySelector('#handle-validation-status');

	if (status === 'AVAILABLE') {
		$handleStatus.innerText = status;
		$handleStatus.style.color = '#5cb85c';
		enableSubmitButton();
	} else {
		$handleStatus.innerText = status;
		$handleStatus.style.color = '#ff0000';
		disableSubmitButton();
	}
};

export default updatePage;
