import restaurantItemsSearchOrchestration from './functions/restaurant_show_main_functions';
import preventFormSubmit from '../shared/prevent_form_submit';

let timerId;
const debounce = (callback, delay) => {
	clearTimeout(timerId);
	timerId = setTimeout(callback, delay);
};

// Orchestrates all combinations of user actions on page that could result in a search being activated
const showSearch = () => {
	const form = document.querySelector('#restaurant-search-form');
	const formInput = document.querySelector('#restaurant-show-search');
	const restaurantShowFiltersConfirmButton = document.querySelector('#restaurant-show-filters-confirm-button');
	const pricefilterCounterCloseButtonDiv = document.querySelector('#price-counter-button-restaurant-show');
	const tagsfilterCounterCloseButtonDiv = document.querySelector('#tags-counter-button-restaurant-show');
	const filterModal = document.getElementById('restaurantShowFilterModal');

	// Prevent enter press on form activating a http request
	if (form) {
		preventFormSubmit(form);
	}

	// User input in search bar triggers search (with debounce delay)
	if (formInput) {
		formInput.addEventListener('input', (event) => {
			debounce(() => restaurantItemsSearchOrchestration(formInput.value), 500);
		});
	}

	// Pressing confirm button on filter triggers a search and closes the filter modal
	if (restaurantShowFiltersConfirmButton) {
		restaurantShowFiltersConfirmButton.addEventListener('click', () => {
			restaurantItemsSearchOrchestration(formInput.value);

      document.body.style.overflow = 'auto';
			filterModal.classList.remove('visible');
		});
	}

	// Closing the filter counter button (shows if filters active) clears checkboxes for that filter and triggers search orchestration again
	if (pricefilterCounterCloseButtonDiv) {
		const closeButton = pricefilterCounterCloseButtonDiv.querySelector('img');

		closeButton.addEventListener('click', (event) => {
			const checkboxes = document.querySelector(`#collapse-price`).querySelectorAll("input[type='checkbox']");

			checkboxes.forEach((checkbox) => {
				checkbox.checked = false;
			});

			pricefilterCounterCloseButtonDiv.style.display = 'none';

			restaurantItemsSearchOrchestration(formInput.value);
		});
	}

	if (tagsfilterCounterCloseButtonDiv) {
		const closeButton = tagsfilterCounterCloseButtonDiv.querySelector('img');

		closeButton.addEventListener('click', (event) => {
			const checkboxes = document.querySelector(`#collapse-tags`).querySelectorAll("input[type='checkbox']");

			checkboxes.forEach((checkbox) => {
				checkbox.checked = false;
			});

			tagsfilterCounterCloseButtonDiv.style.display = 'none';

			restaurantItemsSearchOrchestration(formInput.value);
		});
	}

	// Closing filter modal 'x' button triggers search orchestration
	if (filterModal) {
		const filterModalCloseImg = document.querySelector('.restaurant-show-filter-modal-close').querySelector('img');
		filterModalCloseImg.addEventListener('click', () => {
			restaurantItemsSearchOrchestration(formInput.value);
		});
	}
};

export default showSearch;
