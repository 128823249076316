const featurePostActive = () => {
  const featurePostActivePage = document.querySelector('.featurePostActivate');
  const removePostShows = document.querySelectorAll('.remove-post-show');
  const featurePostShows = document.querySelectorAll('.feature-post-show');
  const removePostHiddens = document.querySelectorAll('.remove-post-hidden');
  const featurePostHiddens = document.querySelectorAll('.feature-post-hidden');

  if (featurePostActivePage) {

    removePostShows.forEach((button) => {
      button.addEventListener('click', () => {
        const featurePostHidden = button.parentNode.querySelector('.feature-post-hidden');
        const featuredTagShow = button.parentNode.parentNode.parentNode.parentNode.querySelector('.featured-tag-show');

        featurePostHidden.style.display = 'block';
        button.style.display = 'none';
        featuredTagShow.style.display = 'none';
      });
    });

    featurePostShows.forEach((button) => {
      button.addEventListener('click', () => {
        const removePostHidden = button.parentNode.querySelector('.remove-post-hidden');
        const featuredTagHidden = button.parentNode.parentNode.parentNode.parentNode.querySelector('.featured-tag-hidden');

        removePostHidden.style.display = 'block';
        button.style.display = 'none';
        featuredTagHidden.style.display = 'block';
      });
    });

    removePostHiddens.forEach((button) => {
      button.addEventListener('click', () => {
        const featurePostShow = button.parentNode.querySelector('.feature-post-show');
        const featuredTagHidden = button.parentNode.parentNode.parentNode.parentNode.querySelector('.featured-tag-hidden');

        featurePostShow.style.display = 'block';
        button.style.display = 'none';
        featuredTagHidden.style.display = 'none';
      });
    });

    featurePostHiddens.forEach((button) => {
      button.addEventListener('click', () => {
        const removePostShow = button.parentNode.querySelector('.remove-post-show');
        const featuredTagShow = button.parentNode.parentNode.parentNode.parentNode.querySelector('.featured-tag-show');

        removePostShow.style.display = 'block';
        button.style.display = 'none';
        featuredTagShow.style.display = 'block';
      });
    });

  }
};

export default featurePostActive;
