const delayedModalFade = () => {
	const modal = document.querySelector('#wifi-modal');

	if (modal) {
		const myTimeout = setTimeout(fadeModal, 10000);
	}
};

const fadeModal = () => {
	const modal = document.querySelector('#wifi-modal');
	modal.style.display = 'none';
};

export default delayedModalFade;
