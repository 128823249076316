const addListenerAddedClass = (element) => {
  element.classList.add('listener-added');
};

const plusButtonListener = (button) => {
  button.addEventListener('click', () => {
    const listContainer = button.parentNode.parentNode.parentNode.querySelector('.list-form-container');
    listContainer.classList.toggle('hidden');
  });
};

const backButtonListener = (button) => {
  button.addEventListener('click', () => {
    const listContainer = button.parentNode.parentNode.parentNode.parentNode.querySelector('.list-form-container');
    listContainer.classList.add('hidden');
  });
};

const listModalPlusButtonsActions = (buttonList) => {
  let count = 0;

  buttonList.forEach((button, index) => {
    addListenerAddedClass(button);
    plusButtonListener(button);
    count += index;
  });

  return count;
};

const listModalNewListModalBackArrowButtonsActions = (buttonList) => {
  buttonList.forEach((button) => {
    addListenerAddedClass(button);
    backButtonListener(button);
  });
};

export { listModalPlusButtonsActions, listModalNewListModalBackArrowButtonsActions };
