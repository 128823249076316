import updatePage from './update_page';

const usernameFetch = async (baseUrl, username, userId) => {
	let url = `${baseUrl}/api/v1/users/validate?username=${username}`;
	if (typeof userId !== 'undefined') {
		url = `${baseUrl}/api/v1/users/validate?username=${username}&id=${userId}`;
	}

	const response = await fetch(url);
	const data = await response.json();
	const { status } = data;
	updatePage(status);
};

export default usernameFetch;
